import { Link, useNavigate } from "react-router-dom"
import { useAuthContext } from "../context/AuthContext"
import VimscoreLogo from "../assets/images/vimscore-logo-colorful.png"

export default function Header() {
  const { setAccessToken } = useAuthContext()
  const navigate = useNavigate()

  const handleLogOut = () => {
    setAccessToken("")
    navigate("/")
  }
  return (
    <div className="flex justify-between">
      <div className="flex flex-row">
        <Link to={"/me"} style={{ display: "flex" }} className="items-center">
          <img alt="VimScore logo" src={VimscoreLogo} className="h-10 mr-2 md:h-14 md:mr-4" />
          <h2 className="pb-0">VimScore</h2>
        </Link>
      </div>
      <button onClick={handleLogOut} className="btn-primary">
        Log out
      </button>
    </div>
  )
}
