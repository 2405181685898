export const stringIsMailAdress = (email: string) => {
  if (
    String(email)
      .toLowerCase()
      .match(/\S+@\S+\.\S+/)
  ) {
    return true
  }
  return false
}
