import ChallengeView from "./views/ChallengeView"
import EnterEmailToken from "./views/EnterEmailToken"
import MainLayout from "./views/MainLayout"
import LoginView from "./views/LoginView"
import MyVimScore from "./views/MyVimScore"
import { ProtectedRoute } from "./components/ProtectedRoute"
import { ProtectedRouteProps } from "./types"
import { Route, Routes } from "react-router-dom"
import { axiosInstance } from "./utils/api"
import { useAuthContext } from "./context/AuthContext"

function App() {
  const { accessToken, isAuthenticated } = useAuthContext()

  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, "outlet"> = {
    isAuthenticated: isAuthenticated(),
    redirectTo: "/",
  }
  if (accessToken) {
    axiosInstance.interceptors.request.use(async function(config: any) {
      try {
        config.headers["Authorization"] = "Bearer " + accessToken
      } catch (e) {
        console.log(e)
      }

      return config
    })
  }

  return (
    <Routes>
      <Route path="/" element={<LoginView />} />
      <Route path="/auth" element={<EnterEmailToken />} />
      <Route path="/" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<MainLayout />} />}>
        <Route path="me" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<MyVimScore />} />} />
        <Route
          path="challenges/:challengeUuid"
          element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ChallengeView />} />}
        />
        <Route
          path="challenges/:challengeUuid/:week"
          element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ChallengeView />} />}
        />
      </Route>
    </Routes>
  )
}

export default App
