/* tslint:disable */
/* eslint-disable */
/**
 * Statera API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.1
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Configuration } from "./configuration"
import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from "axios"
// Some imports not used depending on template conditions
// @ts-ignore
import {
  DUMMY_BASE_URL,
  assertParamExists,
  setApiKeyToObject,
  setBasicAuthToObject,
  setBearerAuthToObject,
  setOAuthToObject,
  setSearchParams,
  serializeDataIfNeeded,
  toPathString,
  createRequestFunction,
} from "./common"
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from "./base"

/**
 *
 * @export
 * @interface AccountInfo
 */
export interface AccountInfo {
  /**
   *
   * @type {number}
   * @memberof AccountInfo
   */
  accountId?: number
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof AccountInfo
   */
  personUuid?: string
}
/**
 *
 * @export
 * @interface ActivityAndWorkout
 */
export interface ActivityAndWorkout {
  /**
   *
   * @type {ActivitySessionDto}
   * @memberof ActivityAndWorkout
   */
  activity?: ActivitySessionDto
  /**
   *
   * @type {WorkoutDto}
   * @memberof ActivityAndWorkout
   */
  workout?: WorkoutDto
}
/**
 *
 * @export
 * @enum {string}
 */

export const ActivityLevel = {
  Sedentary: "SEDENTARY",
  Sporty: "SPORTY",
  Vigorous: "VIGOROUS",
} as const

export type ActivityLevel = typeof ActivityLevel[keyof typeof ActivityLevel]

/**
 *
 * @export
 * @interface ActivityPartDto
 */
export interface ActivityPartDto {
  /**
   *
   * @type {string}
   * @memberof ActivityPartDto
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ActivityPartDto
   */
  description?: string
  /**
   *
   * @type {BodyPart}
   * @memberof ActivityPartDto
   */
  bodyPart?: BodyPart
  /**
   *
   * @type {ExerciseStyle}
   * @memberof ActivityPartDto
   */
  style?: ExerciseStyle
  /**
   *
   * @type {DurationDto}
   * @memberof ActivityPartDto
   */
  exerciseDuration?: DurationDto
  /**
   *
   * @type {WorkoutPart}
   * @memberof ActivityPartDto
   */
  part?: WorkoutPart
  /**
   *
   * @type {string}
   * @memberof ActivityPartDto
   */
  start?: string
  /**
   *
   * @type {string}
   * @memberof ActivityPartDto
   */
  stop?: string
  /**
   *
   * @type {DurationDto}
   * @memberof ActivityPartDto
   */
  timerDuration?: DurationDto
  /**
   *
   * @type {number}
   * @memberof ActivityPartDto
   */
  repetitions?: number
  /**
   *
   * @type {DurationDto}
   * @memberof ActivityPartDto
   */
  durationPerRepetition?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof ActivityPartDto
   */
  effortPerRepetition?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof ActivityPartDto
   */
  recoveryBetweenRepetitions?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof ActivityPartDto
   */
  recoveryBetweenSets?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof ActivityPartDto
   */
  recoveryAfterEffort?: DurationDto
  /**
   *
   * @type {boolean}
   * @memberof ActivityPartDto
   */
  completed?: boolean
  /**
   *
   * @type {Capacity}
   * @memberof ActivityPartDto
   */
  capacity?: Capacity
  /**
   *
   * @type {Array<ActivityWeightSetDto>}
   * @memberof ActivityPartDto
   */
  weightSets?: Array<ActivityWeightSetDto>
  /**
   *
   * @type {string}
   * @memberof ActivityPartDto
   */
  exerciseDescription?: string
}
/**
 *
 * @export
 * @interface ActivityReportRequestDto
 */
export interface ActivityReportRequestDto {
  /**
   *
   * @type {string}
   * @memberof ActivityReportRequestDto
   */
  from: string
  /**
   *
   * @type {string}
   * @memberof ActivityReportRequestDto
   */
  to: string
  /**
   *
   * @type {boolean}
   * @memberof ActivityReportRequestDto
   */
  humanMode?: boolean
}
/**
 *
 * @export
 * @interface ActivitySessionDto
 */
export interface ActivitySessionDto {
  /**
   *
   * @type {string}
   * @memberof ActivitySessionDto
   */
  createdDate?: string
  /**
   *
   * @type {string}
   * @memberof ActivitySessionDto
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof ActivitySessionDto
   */
  start?: string
  /**
   *
   * @type {string}
   * @memberof ActivitySessionDto
   */
  stop?: string
  /**
   *
   * @type {DurationDto}
   * @memberof ActivitySessionDto
   */
  duration?: DurationDto
  /**
   *
   * @type {string}
   * @memberof ActivitySessionDto
   */
  basedOnWorkout?: string
  /**
   *
   * @type {Environment}
   * @memberof ActivitySessionDto
   */
  environment?: Environment
  /**
   *
   * @type {Array<ActivityPartDto>}
   * @memberof ActivitySessionDto
   */
  parts?: Array<ActivityPartDto>
}
/**
 *
 * @export
 * @interface ActivityWeightSetDto
 */
export interface ActivityWeightSetDto {
  /**
   *
   * @type {number}
   * @memberof ActivityWeightSetDto
   */
  repetitions?: number
  /**
   *
   * @type {number}
   * @memberof ActivityWeightSetDto
   */
  weight?: number
  /**
   *
   * @type {BodySide}
   * @memberof ActivityWeightSetDto
   */
  bodySide?: BodySide
}
/**
 *
 * @export
 * @interface AddPhoneNumberConsoleReqDto
 */
export interface AddPhoneNumberConsoleReqDto {
  /**
   *
   * @type {string}
   * @memberof AddPhoneNumberConsoleReqDto
   */
  phoneNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof AddPhoneNumberConsoleReqDto
   */
  verified?: boolean
}
/**
 *
 * @export
 * @enum {string}
 */

export const Age = {
  Age1824: "AGE18_24",
  Age2534: "AGE25_34",
  Age3549: "AGE35_49",
  Age5059: "AGE50_59",
  Age6069: "AGE60_69",
  Age70: "AGE70",
} as const

export type Age = typeof Age[keyof typeof Age]

/**
 *
 * @export
 * @interface AnimationDto
 */
export interface AnimationDto {
  /**
   *
   * @type {number}
   * @memberof AnimationDto
   */
  id?: number
  /**
   *
   * @type {string}
   * @memberof AnimationDto
   */
  externalExerciseId?: string
  /**
   *
   * @type {string}
   * @memberof AnimationDto
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof AnimationDto
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof AnimationDto
   */
  fileName?: string
  /**
   *
   * @type {string}
   * @memberof AnimationDto
   */
  thumbnail?: string
  /**
   *
   * @type {string}
   * @memberof AnimationDto
   */
  lottie?: string
  /**
   *
   * @type {string}
   * @memberof AnimationDto
   */
  mov?: string
  /**
   *
   * @type {string}
   * @memberof AnimationDto
   */
  mp4?: string
}
/**
 *
 * @export
 * @interface AssessmentDto
 */
export interface AssessmentDto {
  /**
   *
   * @type {string}
   * @memberof AssessmentDto
   */
  createdDate?: string
  /**
   *
   * @type {TestType}
   * @memberof AssessmentDto
   */
  testType: TestType
  /**
   *
   * @type {number}
   * @memberof AssessmentDto
   */
  strength?: number
  /**
   *
   * @type {number}
   * @memberof AssessmentDto
   */
  endurance?: number
  /**
   *
   * @type {number}
   * @memberof AssessmentDto
   */
  flexibility?: number
  /**
   *
   * @type {number}
   * @memberof AssessmentDto
   */
  balance?: number
}
/**
 *
 * @export
 * @interface AuthenticationRequestDto
 */
export interface AuthenticationRequestDto {
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequestDto
   */
  email: string
  /**
   *
   * @type {string}
   * @memberof AuthenticationRequestDto
   */
  password: string
}
/**
 *
 * @export
 * @interface AuthenticationResponseDto
 */
export interface AuthenticationResponseDto {
  /**
   *
   * @type {string}
   * @memberof AuthenticationResponseDto
   */
  message?: string
  /**
   *
   * @type {string}
   * @memberof AuthenticationResponseDto
   */
  token?: string
}
/**
 *
 * @export
 * @interface BalanceProgram
 */
export interface BalanceProgram {
  /**
   *
   * @type {string}
   * @memberof BalanceProgram
   */
  cellId?: string
  /**
   *
   * @type {CellDuration}
   * @memberof BalanceProgram
   */
  cellDuration?: CellDuration
  /**
   *
   * @type {DurationDto}
   * @memberof BalanceProgram
   */
  duration?: DurationDto
  /**
   *
   * @type {Array<string>}
   * @memberof BalanceProgram
   */
  aims?: Array<string>
  /**
   *
   * @type {Circuit}
   * @memberof BalanceProgram
   */
  circuit?: Circuit
  /**
   *
   * @type {Array<BalanceProgramStep>}
   * @memberof BalanceProgram
   */
  steps?: Array<BalanceProgramStep>
}
/**
 *
 * @export
 * @interface BalanceProgramStep
 */
export interface BalanceProgramStep {
  /**
   *
   * @type {number}
   * @memberof BalanceProgramStep
   */
  stepNumber?: number
  /**
   *
   * @type {object}
   * @memberof BalanceProgramStep
   */
  effort?: object
}
/**
 *
 * @export
 * @enum {string}
 */

export const BillingInterval = {
  None: "NONE",
  Monthly: "MONTHLY",
  Yearly: "YEARLY",
} as const

export type BillingInterval = typeof BillingInterval[keyof typeof BillingInterval]

/**
 *
 * @export
 * @enum {string}
 */

export const BodyPart = {
  FrontalForearm: "FRONTAL_FOREARM",
  DorsalForearm: "DORSAL_FOREARM",
  FrontalUpperArm: "FRONTAL_UPPER_ARM",
  DorsalUpperArm: "DORSAL_UPPER_ARM",
  Shoulder: "SHOULDER",
  Neck: "NECK",
  FrontalUpperBody: "FRONTAL_UPPER_BODY",
  DorsalUpperBody: "DORSAL_UPPER_BODY",
  FrontalCore: "FRONTAL_CORE",
  DorsalCore: "DORSAL_CORE",
  TransverseCore: "TRANSVERSE_CORE",
  FrontalLegs: "FRONTAL_LEGS",
  DorsalLegs: "DORSAL_LEGS",
  TransverseLegs: "TRANSVERSE_LEGS",
  FrontalCalves: "FRONTAL_CALVES",
  DorsalCalves: "DORSAL_CALVES",
  Global: "GLOBAL",
} as const

export type BodyPart = typeof BodyPart[keyof typeof BodyPart]

/**
 *
 * @export
 * @enum {string}
 */

export const BodySide = {
  L: "L",
  R: "R",
} as const

export type BodySide = typeof BodySide[keyof typeof BodySide]

/**
 *
 * @export
 * @enum {string}
 */

export const Capacity = {
  Strength: "STRENGTH",
  Endurance: "ENDURANCE",
  Balance: "BALANCE",
  Flexibility: "FLEXIBILITY",
} as const

export type Capacity = typeof Capacity[keyof typeof Capacity]

/**
 *
 * @export
 * @enum {string}
 */

export const CapacityLevel = {
  Basic: "BASIC",
  Active: "ACTIVE",
  Sporty: "SPORTY",
  Extreme: "EXTREME",
} as const

export type CapacityLevel = typeof CapacityLevel[keyof typeof CapacityLevel]

/**
 *
 * @export
 * @interface CapacityLevels
 */
export interface CapacityLevels {
  /**
   *
   * @type {CapacityLevel}
   * @memberof CapacityLevels
   */
  strength?: CapacityLevel
  /**
   *
   * @type {CapacityLevel}
   * @memberof CapacityLevels
   */
  endurance?: CapacityLevel
  /**
   *
   * @type {CapacityLevel}
   * @memberof CapacityLevels
   */
  balance?: CapacityLevel
  /**
   *
   * @type {CapacityLevel}
   * @memberof CapacityLevels
   */
  flexibility?: CapacityLevel
}
/**
 *
 * @export
 * @interface CapacityMinutesDto
 */
export interface CapacityMinutesDto {
  /**
   *
   * @type {DurationDto}
   * @memberof CapacityMinutesDto
   */
  strength: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof CapacityMinutesDto
   */
  endurance: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof CapacityMinutesDto
   */
  balance: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof CapacityMinutesDto
   */
  flexibility: DurationDto
}
/**
 *
 * @export
 * @interface CapacityProgressDto
 */
export interface CapacityProgressDto {
  /**
   *
   * @type {number}
   * @memberof CapacityProgressDto
   */
  strength: number
  /**
   *
   * @type {number}
   * @memberof CapacityProgressDto
   */
  endurance: number
  /**
   *
   * @type {number}
   * @memberof CapacityProgressDto
   */
  balance: number
  /**
   *
   * @type {number}
   * @memberof CapacityProgressDto
   */
  flexibility: number
}
/**
 *
 * @export
 * @interface CapacityScores
 */
export interface CapacityScores {
  /**
   *
   * @type {number}
   * @memberof CapacityScores
   */
  strength?: number
  /**
   *
   * @type {number}
   * @memberof CapacityScores
   */
  endurance?: number
  /**
   *
   * @type {number}
   * @memberof CapacityScores
   */
  balance?: number
  /**
   *
   * @type {number}
   * @memberof CapacityScores
   */
  flexibility?: number
}
/**
 *
 * @export
 * @interface CapacityScoresDto
 */
export interface CapacityScoresDto {
  /**
   *
   * @type {number}
   * @memberof CapacityScoresDto
   */
  strength?: number
  /**
   *
   * @type {number}
   * @memberof CapacityScoresDto
   */
  endurance?: number
  /**
   *
   * @type {number}
   * @memberof CapacityScoresDto
   */
  balance?: number
  /**
   *
   * @type {number}
   * @memberof CapacityScoresDto
   */
  flexibility?: number
}
/**
 *
 * @export
 * @enum {string}
 */

export const CellDuration = {
  Xxxs: "XXXS",
  Xxs: "XXS",
  Xs: "XS",
  S: "S",
  Sm: "SM",
  M: "M",
  L: "L",
  Xl: "XL",
  Xxl: "XXL",
  Xxxl: "XXXL",
} as const

export type CellDuration = typeof CellDuration[keyof typeof CellDuration]

/**
 *
 * @export
 * @interface ChallengeDetailsDto
 */
export interface ChallengeDetailsDto {
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailsDto
   */
  uuid: string
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailsDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailsDto
   */
  startWeek: string
  /**
   *
   * @type {string}
   * @memberof ChallengeDetailsDto
   */
  webLink?: string
  /**
   *
   * @type {Array<ChallengeMemberDto>}
   * @memberof ChallengeDetailsDto
   */
  members?: Array<ChallengeMemberDto>
  /**
   *
   * @type {boolean}
   * @memberof ChallengeDetailsDto
   */
  teamChallenge: boolean
  /**
   *
   * @type {Array<ChallengeTeamDto>}
   * @memberof ChallengeDetailsDto
   */
  teams?: Array<ChallengeTeamDto>
}
/**
 *
 * @export
 * @interface ChallengeDto
 */
export interface ChallengeDto {
  /**
   *
   * @type {string}
   * @memberof ChallengeDto
   */
  uuid: string
  /**
   *
   * @type {string}
   * @memberof ChallengeDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ChallengeDto
   */
  startWeek?: string
  /**
   *
   * @type {boolean}
   * @memberof ChallengeDto
   */
  teamChallenge: boolean
  /**
   *
   * @type {string}
   * @memberof ChallengeDto
   */
  webLink?: string
}
/**
 *
 * @export
 * @interface ChallengeMemberDto
 */
export interface ChallengeMemberDto {
  /**
   *
   * @type {string}
   * @memberof ChallengeMemberDto
   */
  person: string
  /**
   *
   * @type {string}
   * @memberof ChallengeMemberDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ChallengeMemberDto
   */
  team?: string
  /**
   *
   * @type {boolean}
   * @memberof ChallengeMemberDto
   */
  adminFlag?: boolean
}
/**
 *
 * @export
 * @interface ChallengeMemberWeekDto
 */
export interface ChallengeMemberWeekDto {
  /**
   *
   * @type {string}
   * @memberof ChallengeMemberWeekDto
   */
  person: string
  /**
   *
   * @type {string}
   * @memberof ChallengeMemberWeekDto
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof ChallengeMemberWeekDto
   */
  completed?: boolean
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof ChallengeMemberWeekDto
   */
  given: CapacityMinutesDto
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof ChallengeMemberWeekDto
   */
  done: CapacityMinutesDto
  /**
   *
   * @type {CapacityProgressDto}
   * @memberof ChallengeMemberWeekDto
   */
  progress: CapacityProgressDto
}
/**
 *
 * @export
 * @interface ChallengeReportReqDto
 */
export interface ChallengeReportReqDto {
  /**
   *
   * @type {boolean}
   * @memberof ChallengeReportReqDto
   */
  humanMode?: boolean
}
/**
 *
 * @export
 * @interface ChallengeTeamDto
 */
export interface ChallengeTeamDto {
  /**
   *
   * @type {string}
   * @memberof ChallengeTeamDto
   */
  uuid: string
  /**
   *
   * @type {string}
   * @memberof ChallengeTeamDto
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof ChallengeTeamDto
   */
  useGoal: boolean
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof ChallengeTeamDto
   */
  goal?: CapacityMinutesDto
}
/**
 *
 * @export
 * @interface ChallengeTeamWeekDto
 */
export interface ChallengeTeamWeekDto {
  /**
   *
   * @type {string}
   * @memberof ChallengeTeamWeekDto
   */
  team: string
  /**
   *
   * @type {string}
   * @memberof ChallengeTeamWeekDto
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof ChallengeTeamWeekDto
   */
  completed?: boolean
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof ChallengeTeamWeekDto
   */
  given: CapacityMinutesDto
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof ChallengeTeamWeekDto
   */
  done: CapacityMinutesDto
  /**
   *
   * @type {CapacityProgressDto}
   * @memberof ChallengeTeamWeekDto
   */
  progress: CapacityProgressDto
}
/**
 *
 * @export
 * @interface ChallengeWeekDto
 */
export interface ChallengeWeekDto {
  /**
   *
   * @type {string}
   * @memberof ChallengeWeekDto
   */
  uuid: string
  /**
   *
   * @type {string}
   * @memberof ChallengeWeekDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof ChallengeWeekDto
   */
  myTeam?: string
  /**
   *
   * @type {Array<ChallengeMemberWeekDto>}
   * @memberof ChallengeWeekDto
   */
  members?: Array<ChallengeMemberWeekDto>
  /**
   *
   * @type {Array<ChallengeTeamWeekDto>}
   * @memberof ChallengeWeekDto
   */
  teams?: Array<ChallengeTeamWeekDto>
}
/**
 *
 * @export
 * @interface CheckRequestDto
 */
export interface CheckRequestDto {
  /**
   *
   * @type {string}
   * @memberof CheckRequestDto
   */
  platform?: string
  /**
   *
   * @type {string}
   * @memberof CheckRequestDto
   */
  buildNumber?: string
  /**
   *
   * @type {string}
   * @memberof CheckRequestDto
   */
  packageName?: string
  /**
   *
   * @type {string}
   * @memberof CheckRequestDto
   */
  version?: string
}
/**
 *
 * @export
 * @interface CheckResponseDto
 */
export interface CheckResponseDto {
  /**
   *
   * @type {boolean}
   * @memberof CheckResponseDto
   */
  upgradeRequired?: boolean
}
/**
 *
 * @export
 * @interface Circuit
 */
export interface Circuit {
  /**
   *
   * @type {number}
   * @memberof Circuit
   */
  repetitions?: number
  /**
   *
   * @type {DurationDto}
   * @memberof Circuit
   */
  recovery?: DurationDto
}
/**
 *
 * @export
 * @interface ConsoleAccountDto
 */
export interface ConsoleAccountDto {
  /**
   *
   * @type {number}
   * @memberof ConsoleAccountDto
   */
  accountId?: number
  /**
   *
   * @type {number}
   * @memberof ConsoleAccountDto
   */
  consoleAccountId?: number
  /**
   *
   * @type {string}
   * @memberof ConsoleAccountDto
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof ConsoleAccountDto
   */
  name?: string
}
/**
 *
 * @export
 * @interface ContinueEmailAuthenticationReqDto
 */
export interface ContinueEmailAuthenticationReqDto {
  /**
   *
   * @type {string}
   * @memberof ContinueEmailAuthenticationReqDto
   */
  deviceToken?: string
  /**
   *
   * @type {string}
   * @memberof ContinueEmailAuthenticationReqDto
   */
  emailToken: string
}
/**
 *
 * @export
 * @interface ContinueEmailAuthenticationResDto
 */
export interface ContinueEmailAuthenticationResDto {
  /**
   *
   * @type {ResponseCode}
   * @memberof ContinueEmailAuthenticationResDto
   */
  code?: ResponseCode
  /**
   *
   * @type {string}
   * @memberof ContinueEmailAuthenticationResDto
   */
  person?: string
  /**
   *
   * @type {string}
   * @memberof ContinueEmailAuthenticationResDto
   */
  authenticationToken?: string
}
/**
 *
 * @export
 * @interface ContinuePasswordRecoveryReqDto
 */
export interface ContinuePasswordRecoveryReqDto {
  /**
   *
   * @type {string}
   * @memberof ContinuePasswordRecoveryReqDto
   */
  token?: string
  /**
   *
   * @type {string}
   * @memberof ContinuePasswordRecoveryReqDto
   */
  newPassword?: string
}
/**
 *
 * @export
 * @interface CreateChallengeReqDto
 */
export interface CreateChallengeReqDto {
  /**
   *
   * @type {string}
   * @memberof CreateChallengeReqDto
   */
  name: string
  /**
   *
   * @type {boolean}
   * @memberof CreateChallengeReqDto
   */
  teamChallenge: boolean
  /**
   *
   * @type {string}
   * @memberof CreateChallengeReqDto
   */
  spondSubgroup?: string
}
/**
 *
 * @export
 * @interface CreateChallengeTeamReqDto
 */
export interface CreateChallengeTeamReqDto {
  /**
   *
   * @type {string}
   * @memberof CreateChallengeTeamReqDto
   */
  challenge: string
  /**
   *
   * @type {string}
   * @memberof CreateChallengeTeamReqDto
   */
  name: string
}
/**
 *
 * @export
 * @interface CreateConsoleAccountReqDto
 */
export interface CreateConsoleAccountReqDto {
  /**
   *
   * @type {number}
   * @memberof CreateConsoleAccountReqDto
   */
  accountId?: number
  /**
   *
   * @type {string}
   * @memberof CreateConsoleAccountReqDto
   */
  name?: string
}
/**
 *
 * @export
 * @interface CreateDump4tuneReportRequestDto
 */
export interface CreateDump4tuneReportRequestDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateDump4tuneReportRequestDto
   */
  dumpCells?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateDump4tuneReportRequestDto
   */
  dumpCellExercises?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateDump4tuneReportRequestDto
   */
  dumpExercises?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateDump4tuneReportRequestDto
   */
  filterExercises?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateDump4tuneReportRequestDto
   */
  dumpGoals?: boolean
}
/**
 *
 * @export
 * @interface CreateExercisesReportRequestDto
 */
export interface CreateExercisesReportRequestDto {
  /**
   *
   * @type {boolean}
   * @memberof CreateExercisesReportRequestDto
   */
  humanMode: boolean
}
/**
 *
 * @export
 * @interface CreatePersonReqDto
 */
export interface CreatePersonReqDto {
  /**
   *
   * @type {string}
   * @memberof CreatePersonReqDto
   */
  name: string
  /**
   *
   * @type {string}
   * @memberof CreatePersonReqDto
   */
  dateOfBirth: string
  /**
   *
   * @type {Gender}
   * @memberof CreatePersonReqDto
   */
  gender: Gender
  /**
   *
   * @type {ActivityLevel}
   * @memberof CreatePersonReqDto
   */
  activityLevel: ActivityLevel
  /**
   *
   * @type {AssessmentDto}
   * @memberof CreatePersonReqDto
   */
  assessment: AssessmentDto
  /**
   *
   * @type {string}
   * @memberof CreatePersonReqDto
   */
  emailAddress?: string
  /**
   *
   * @type {string}
   * @memberof CreatePersonReqDto
   */
  phoneNumber?: string
}
/**
 *
 * @export
 * @interface CreatePickExercisesReportRequestDto
 */
export interface CreatePickExercisesReportRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreatePickExercisesReportRequestDto
   */
  exerciseTags: string
  /**
   *
   * @type {boolean}
   * @memberof CreatePickExercisesReportRequestDto
   */
  approvedOnly?: boolean
  /**
   *
   * @type {Environment}
   * @memberof CreatePickExercisesReportRequestDto
   */
  environment?: Environment
  /**
   *
   * @type {number}
   * @memberof CreatePickExercisesReportRequestDto
   */
  maxNumberOfAlternativeExercises?: number
  /**
   *
   * @type {string}
   * @memberof CreatePickExercisesReportRequestDto
   */
  strengthTag?: string
  /**
   *
   * @type {number}
   * @memberof CreatePickExercisesReportRequestDto
   */
  strengthPicks?: number
  /**
   *
   * @type {string}
   * @memberof CreatePickExercisesReportRequestDto
   */
  enduranceTag?: string
  /**
   *
   * @type {number}
   * @memberof CreatePickExercisesReportRequestDto
   */
  endurancePicks?: number
  /**
   *
   * @type {string}
   * @memberof CreatePickExercisesReportRequestDto
   */
  balanceTag?: string
  /**
   *
   * @type {number}
   * @memberof CreatePickExercisesReportRequestDto
   */
  balancePicks?: number
  /**
   *
   * @type {string}
   * @memberof CreatePickExercisesReportRequestDto
   */
  flexibilityTag?: string
  /**
   *
   * @type {number}
   * @memberof CreatePickExercisesReportRequestDto
   */
  flexibilityPicks?: number
}
/**
 *
 * @export
 * @interface CreateProgramsReportRequestDto
 */
export interface CreateProgramsReportRequestDto {
  /**
   *
   * @type {string}
   * @memberof CreateProgramsReportRequestDto
   */
  locale?: string
  /**
   *
   * @type {string}
   * @memberof CreateProgramsReportRequestDto
   */
  strengthTag: string
  /**
   *
   * @type {string}
   * @memberof CreateProgramsReportRequestDto
   */
  enduranceTag: string
  /**
   *
   * @type {string}
   * @memberof CreateProgramsReportRequestDto
   */
  balanceTag: string
  /**
   *
   * @type {string}
   * @memberof CreateProgramsReportRequestDto
   */
  flexibilityTag: string
  /**
   *
   * @type {string}
   * @memberof CreateProgramsReportRequestDto
   */
  exerciseTags?: string
  /**
   *
   * @type {Environment}
   * @memberof CreateProgramsReportRequestDto
   */
  environment?: Environment
  /**
   *
   * @type {boolean}
   * @memberof CreateProgramsReportRequestDto
   */
  approvedOnly?: boolean
  /**
   *
   * @type {boolean}
   * @memberof CreateProgramsReportRequestDto
   */
  humanMode?: boolean
}
/**
 *
 * @export
 * @interface CreateWorkoutReqDto
 */
export interface CreateWorkoutReqDto {
  /**
   *
   * @type {number}
   * @memberof CreateWorkoutReqDto
   */
  totalTime?: number
  /**
   *
   * @type {Environment}
   * @memberof CreateWorkoutReqDto
   */
  environment?: Environment
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof CreateWorkoutReqDto
   */
  warmUp?: CapacityMinutesDto
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof CreateWorkoutReqDto
   */
  main?: CapacityMinutesDto
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof CreateWorkoutReqDto
   */
  coolDown?: CapacityMinutesDto
  /**
   *
   * @type {MaxDurations}
   * @memberof CreateWorkoutReqDto
   */
  maxDurations?: MaxDurations
}
/**
 *
 * @export
 * @interface CreateWorkoutRequestDto
 */
export interface CreateWorkoutRequestDto {
  /**
   *
   * @type {number}
   * @memberof CreateWorkoutRequestDto
   */
  maxNumberOfAlternativeExercises?: number
  /**
   *
   * @type {Environment}
   * @memberof CreateWorkoutRequestDto
   */
  environment?: Environment
  /**
   *
   * @type {number}
   * @memberof CreateWorkoutRequestDto
   */
  duration?: number
  /**
   *
   * @type {ActivityLevel}
   * @memberof CreateWorkoutRequestDto
   */
  activityLevel?: ActivityLevel
  /**
   *
   * @type {CapacityScoresDto}
   * @memberof CreateWorkoutRequestDto
   */
  capacityScores?: CapacityScoresDto
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof CreateWorkoutRequestDto
   */
  phaseCapacityMinutes?: CapacityMinutesDto
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof CreateWorkoutRequestDto
   */
  remainingMinutesForWeek?: CapacityMinutesDto
}
/**
 *
 * @export
 * @interface CreateWorkoutSpecificationReqDto
 */
export interface CreateWorkoutSpecificationReqDto {
  /**
   *
   * @type {number}
   * @memberof CreateWorkoutSpecificationReqDto
   */
  duration: number
  /**
   *
   * @type {MaxDurations}
   * @memberof CreateWorkoutSpecificationReqDto
   */
  maxDurations?: MaxDurations
}
/**
 *
 * @export
 * @interface DailyWorkout
 */
export interface DailyWorkout {
  /**
   *
   * @type {WorkoutSpecification}
   * @memberof DailyWorkout
   */
  specification?: WorkoutSpecification
  /**
   *
   * @type {DailyWorkoutPart}
   * @memberof DailyWorkout
   */
  warmUp?: DailyWorkoutPart
  /**
   *
   * @type {DailyWorkoutPart}
   * @memberof DailyWorkout
   */
  main?: DailyWorkoutPart
  /**
   *
   * @type {DailyWorkoutPart}
   * @memberof DailyWorkout
   */
  coolDown?: DailyWorkoutPart
  /**
   *
   * @type {Array<string>}
   * @memberof DailyWorkout
   */
  log?: Array<string>
}
/**
 *
 * @export
 * @interface DailyWorkoutExercise
 */
export interface DailyWorkoutExercise {
  /**
   *
   * @type {string}
   * @memberof DailyWorkoutExercise
   */
  cellId?: string
  /**
   *
   * @type {Capacity}
   * @memberof DailyWorkoutExercise
   */
  capacity?: Capacity
  /**
   *
   * @type {LateralType}
   * @memberof DailyWorkoutExercise
   */
  lateralType?: LateralType
  /**
   *
   * @type {BodyPart}
   * @memberof DailyWorkoutExercise
   */
  bodyPart?: BodyPart
  /**
   *
   * @type {Array<CapacityLevel>}
   * @memberof DailyWorkoutExercise
   */
  capacityLevels?: Array<CapacityLevel>
  /**
   *
   * @type {DurationDto}
   * @memberof DailyWorkoutExercise
   */
  duration?: DurationDto
  /**
   *
   * @type {Effort}
   * @memberof DailyWorkoutExercise
   */
  effort?: Effort
  /**
   *
   * @type {Array<string>}
   * @memberof DailyWorkoutExercise
   */
  alternatives?: Array<string>
}
/**
 *
 * @export
 * @interface DailyWorkoutExerciseGroup
 */
export interface DailyWorkoutExerciseGroup {
  /**
   *
   * @type {number}
   * @memberof DailyWorkoutExerciseGroup
   */
  repetitions?: number
  /**
   *
   * @type {Array<DailyWorkoutExercise>}
   * @memberof DailyWorkoutExerciseGroup
   */
  workoutExercises?: Array<DailyWorkoutExercise>
  /**
   *
   * @type {Array<string>}
   * @memberof DailyWorkoutExerciseGroup
   */
  cells?: Array<string>
  /**
   *
   * @type {Array<string>}
   * @memberof DailyWorkoutExerciseGroup
   */
  exercises?: Array<string>
}
/**
 *
 * @export
 * @interface DailyWorkoutPart
 */
export interface DailyWorkoutPart {
  /**
   *
   * @type {DailyWorkoutExerciseGroup}
   * @memberof DailyWorkoutPart
   */
  strength?: DailyWorkoutExerciseGroup
  /**
   *
   * @type {DailyWorkoutExerciseGroup}
   * @memberof DailyWorkoutPart
   */
  endurance?: DailyWorkoutExerciseGroup
  /**
   *
   * @type {DailyWorkoutExerciseGroup}
   * @memberof DailyWorkoutPart
   */
  balance?: DailyWorkoutExerciseGroup
  /**
   *
   * @type {DailyWorkoutExerciseGroup}
   * @memberof DailyWorkoutPart
   */
  flexibility?: DailyWorkoutExerciseGroup
  /**
   *
   * @type {PartPrograms}
   * @memberof DailyWorkoutPart
   */
  partPrograms?: PartPrograms
}
/**
 *
 * @export
 * @interface DurationDto
 */
export interface DurationDto {
  /**
   *
   * @type {number}
   * @memberof DurationDto
   */
  minutes: number
  /**
   *
   * @type {number}
   * @memberof DurationDto
   */
  seconds: number
}
/**
 * @type Effort
 * @export
 */
export type Effort = SeriesEffort | SetRepEffort | TimeEffort

/**
 *
 * @export
 * @interface EmailAddressDto
 */
export interface EmailAddressDto {
  /**
   *
   * @type {string}
   * @memberof EmailAddressDto
   */
  address?: string
  /**
   *
   * @type {boolean}
   * @memberof EmailAddressDto
   */
  verified?: boolean
}
/**
 *
 * @export
 * @interface EnduranceProgram
 */
export interface EnduranceProgram {
  /**
   *
   * @type {string}
   * @memberof EnduranceProgram
   */
  cellId?: string
  /**
   *
   * @type {CellDuration}
   * @memberof EnduranceProgram
   */
  cellDuration?: CellDuration
  /**
   *
   * @type {DurationDto}
   * @memberof EnduranceProgram
   */
  duration?: DurationDto
  /**
   *
   * @type {Array<Environment>}
   * @memberof EnduranceProgram
   */
  environments?: Array<Environment>
  /**
   *
   * @type {Circuit}
   * @memberof EnduranceProgram
   */
  circuit?: Circuit
  /**
   *
   * @type {string}
   * @memberof EnduranceProgram
   */
  enduranceType?: EnduranceProgramEnduranceTypeEnum
  /**
   *
   * @type {Array<EnduranceProgramStep>}
   * @memberof EnduranceProgram
   */
  steps?: Array<EnduranceProgramStep>
}

export const EnduranceProgramEnduranceTypeEnum = {
  Jump: "JUMP",
  Cardio: "CARDIO",
} as const

export type EnduranceProgramEnduranceTypeEnum =
  typeof EnduranceProgramEnduranceTypeEnum[keyof typeof EnduranceProgramEnduranceTypeEnum]

/**
 *
 * @export
 * @interface EnduranceProgramStep
 */
export interface EnduranceProgramStep {
  /**
   *
   * @type {number}
   * @memberof EnduranceProgramStep
   */
  stepNumber?: number
  /**
   *
   * @type {object}
   * @memberof EnduranceProgramStep
   */
  effort?: object
}
/**
 *
 * @export
 * @enum {string}
 */

export const Environment = {
  Gym: "GYM",
  Home: "HOME",
} as const

export type Environment = typeof Environment[keyof typeof Environment]

/**
 *
 * @export
 * @interface ExerciseDescriptionDto
 */
export interface ExerciseDescriptionDto {
  /**
   *
   * @type {string}
   * @memberof ExerciseDescriptionDto
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof ExerciseDescriptionDto
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof ExerciseDescriptionDto
   */
  description?: string
  /**
   *
   * @type {string}
   * @memberof ExerciseDescriptionDto
   */
  animationUrl?: string
  /**
   *
   * @type {string}
   * @memberof ExerciseDescriptionDto
   */
  thumbnailUrl?: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const ExerciseStyle = {
  Time: "TIME",
  SetRep: "SET_REP",
  TimeRep: "TIME_REP",
} as const

export type ExerciseStyle = typeof ExerciseStyle[keyof typeof ExerciseStyle]

/**
 *
 * @export
 * @interface FlexibilityProgram
 */
export interface FlexibilityProgram {
  /**
   *
   * @type {string}
   * @memberof FlexibilityProgram
   */
  cellId?: string
  /**
   *
   * @type {CellDuration}
   * @memberof FlexibilityProgram
   */
  cellDuration?: CellDuration
  /**
   *
   * @type {DurationDto}
   * @memberof FlexibilityProgram
   */
  duration?: DurationDto
  /**
   *
   * @type {Array<WorkoutPart>}
   * @memberof FlexibilityProgram
   */
  parts?: Array<WorkoutPart>
  /**
   *
   * @type {Array<string>}
   * @memberof FlexibilityProgram
   */
  aims?: Array<string>
  /**
   *
   * @type {Circuit}
   * @memberof FlexibilityProgram
   */
  circuit?: Circuit
  /**
   *
   * @type {Array<FlexibilityProgramStep>}
   * @memberof FlexibilityProgram
   */
  steps?: Array<FlexibilityProgramStep>
}
/**
 *
 * @export
 * @interface FlexibilityProgramStep
 */
export interface FlexibilityProgramStep {
  /**
   *
   * @type {number}
   * @memberof FlexibilityProgramStep
   */
  stepNumber?: number
  /**
   *
   * @type {BodyPart}
   * @memberof FlexibilityProgramStep
   */
  bodyPart?: BodyPart
  /**
   *
   * @type {LateralTypeFilter}
   * @memberof FlexibilityProgramStep
   */
  lateralTypeFilter?: LateralTypeFilter
  /**
   *
   * @type {object}
   * @memberof FlexibilityProgramStep
   */
  effort?: object
}
/**
 *
 * @export
 * @enum {string}
 */

export const Gender = {
  Male: "MALE",
  Female: "FEMALE",
  Other: "OTHER",
} as const

export type Gender = typeof Gender[keyof typeof Gender]

/**
 *
 * @export
 * @enum {string}
 */

export const Goal = {
  Wellness: "WELLNESS",
} as const

export type Goal = typeof Goal[keyof typeof Goal]

/**
 *
 * @export
 * @interface HealthProfileDto
 */
export interface HealthProfileDto {
  /**
   *
   * @type {CapacityScoresDto}
   * @memberof HealthProfileDto
   */
  capacityScores?: CapacityScoresDto
  /**
   *
   * @type {string}
   * @memberof HealthProfileDto
   */
  lastUpdate?: string
}
/**
 *
 * @export
 * @interface InlineObject2
 */
export interface InlineObject2 {
  /**
   *
   * @type {any}
   * @memberof InlineObject2
   */
  lottie: any
  /**
   *
   * @type {Array<string>}
   * @memberof InlineObject2
   */
  thumbnail?: Array<string>
}
/**
 *
 * @export
 * @interface JobResponseDto
 */
export interface JobResponseDto {
  /**
   *
   * @type {Array<JobResponseLineDto>}
   * @memberof JobResponseDto
   */
  messages?: Array<JobResponseLineDto>
}
/**
 *
 * @export
 * @interface JobResponseLineDto
 */
export interface JobResponseLineDto {
  /**
   *
   * @type {number}
   * @memberof JobResponseLineDto
   */
  timestamp?: number
  /**
   *
   * @type {string}
   * @memberof JobResponseLineDto
   */
  level?: string
  /**
   *
   * @type {string}
   * @memberof JobResponseLineDto
   */
  message?: string
}
/**
 *
 * @export
 * @interface JoinChallengeReqDto
 */
export interface JoinChallengeReqDto {
  /**
   *
   * @type {string}
   * @memberof JoinChallengeReqDto
   */
  challenge: string
  /**
   *
   * @type {string}
   * @memberof JoinChallengeReqDto
   */
  team?: string
  /**
   *
   * @type {string}
   * @memberof JoinChallengeReqDto
   */
  person: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const LateralType = {
  Bilateral: "BILATERAL",
  Unilateral: "UNILATERAL",
} as const

export type LateralType = typeof LateralType[keyof typeof LateralType]

/**
 *
 * @export
 * @enum {string}
 */

export const LateralTypeFilter = {
  All: "ALL",
  Unilateral: "UNILATERAL",
  Bilateral: "BILATERAL",
} as const

export type LateralTypeFilter = typeof LateralTypeFilter[keyof typeof LateralTypeFilter]

/**
 *
 * @export
 * @interface LeaveChallengeReqDto
 */
export interface LeaveChallengeReqDto {
  /**
   *
   * @type {string}
   * @memberof LeaveChallengeReqDto
   */
  challenge: string
  /**
   *
   * @type {string}
   * @memberof LeaveChallengeReqDto
   */
  person: string
}
/**
 *
 * @export
 * @interface MaxDurations
 */
export interface MaxDurations {
  /**
   *
   * @type {number}
   * @memberof MaxDurations
   */
  strength?: number
  /**
   *
   * @type {number}
   * @memberof MaxDurations
   */
  endurance?: number
  /**
   *
   * @type {number}
   * @memberof MaxDurations
   */
  balance?: number
  /**
   *
   * @type {number}
   * @memberof MaxDurations
   */
  flexibility?: number
}
/**
 *
 * @export
 * @interface PartPrograms
 */
export interface PartPrograms {
  /**
   *
   * @type {StrengthProgram}
   * @memberof PartPrograms
   */
  strength?: StrengthProgram
  /**
   *
   * @type {EnduranceProgram}
   * @memberof PartPrograms
   */
  endurance?: EnduranceProgram
  /**
   *
   * @type {BalanceProgram}
   * @memberof PartPrograms
   */
  balance?: BalanceProgram
  /**
   *
   * @type {FlexibilityProgram}
   * @memberof PartPrograms
   */
  flexibility?: FlexibilityProgram
  /**
   *
   * @type {Array<Capacity>}
   * @memberof PartPrograms
   */
  executionOrder?: Array<Capacity>
}
/**
 *
 * @export
 * @interface PartSpecification
 */
export interface PartSpecification {
  /**
   *
   * @type {WorkoutPart}
   * @memberof PartSpecification
   */
  part?: WorkoutPart
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof PartSpecification
   */
  capacityMinutes?: CapacityMinutesDto
  /**
   *
   * @type {CellDuration}
   * @memberof PartSpecification
   */
  strength?: CellDuration
  /**
   *
   * @type {CellDuration}
   * @memberof PartSpecification
   */
  endurance?: CellDuration
  /**
   *
   * @type {CellDuration}
   * @memberof PartSpecification
   */
  balance?: CellDuration
  /**
   *
   * @type {CellDuration}
   * @memberof PartSpecification
   */
  flexibility?: CellDuration
}
/**
 *
 * @export
 * @interface PersonDto
 */
export interface PersonDto {
  /**
   *
   * @type {string}
   * @memberof PersonDto
   */
  uuid: string
  /**
   *
   * @type {string}
   * @memberof PersonDto
   */
  created?: string
  /**
   *
   * @type {string}
   * @memberof PersonDto
   */
  name?: string
  /**
   *
   * @type {Gender}
   * @memberof PersonDto
   */
  gender: Gender
  /**
   *
   * @type {ActivityLevel}
   * @memberof PersonDto
   */
  activityLevel: ActivityLevel
  /**
   *
   * @type {string}
   * @memberof PersonDto
   */
  dateOfBirth?: string
  /**
   *
   * @type {Age}
   * @memberof PersonDto
   */
  age?: Age
  /**
   *
   * @type {boolean}
   * @memberof PersonDto
   */
  injuries: boolean
  /**
   *
   * @type {string}
   * @memberof PersonDto
   */
  email?: string
  /**
   *
   * @type {string}
   * @memberof PersonDto
   */
  phoneNumber?: string
  /**
   *
   * @type {Array<EmailAddressDto>}
   * @memberof PersonDto
   */
  emailAddresses?: Array<EmailAddressDto>
  /**
   *
   * @type {Array<PhoneNumberDto>}
   * @memberof PersonDto
   */
  phoneNumbers?: Array<PhoneNumberDto>
}
/**
 *
 * @export
 * @interface PersonInfoDto
 */
export interface PersonInfoDto {
  /**
   *
   * @type {PersonDto}
   * @memberof PersonInfoDto
   */
  person?: PersonDto
  /**
   *
   * @type {HealthProfileDto}
   * @memberof PersonInfoDto
   */
  healthProfile?: HealthProfileDto
  /**
   *
   * @type {Array<PrescriptionDto>}
   * @memberof PersonInfoDto
   */
  prescriptions?: Array<PrescriptionDto>
  /**
   *
   * @type {Array<VimPeriodDto>}
   * @memberof PersonInfoDto
   */
  periods?: Array<VimPeriodDto>
}
/**
 *
 * @export
 * @interface PersonWeekDto
 */
export interface PersonWeekDto {
  /**
   *
   * @type {string}
   * @memberof PersonWeekDto
   */
  yearWeek?: string
  /**
   *
   * @type {boolean}
   * @memberof PersonWeekDto
   */
  completed?: boolean
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof PersonWeekDto
   */
  given?: CapacityMinutesDto
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof PersonWeekDto
   */
  done?: CapacityMinutesDto
  /**
   *
   * @type {CapacityProgressDto}
   * @memberof PersonWeekDto
   */
  progress?: CapacityProgressDto
}
/**
 *
 * @export
 * @interface PhoneNumberDto
 */
export interface PhoneNumberDto {
  /**
   *
   * @type {string}
   * @memberof PhoneNumberDto
   */
  phoneNumber?: string
  /**
   *
   * @type {boolean}
   * @memberof PhoneNumberDto
   */
  verified?: boolean
}
/**
 *
 * @export
 * @interface PrescriptionDto
 */
export interface PrescriptionDto {
  /**
   *
   * @type {string}
   * @memberof PrescriptionDto
   */
  createdDate?: string
  /**
   *
   * @type {Goal}
   * @memberof PrescriptionDto
   */
  goal?: Goal
  /**
   *
   * @type {ActivityLevel}
   * @memberof PrescriptionDto
   */
  activityLevel?: ActivityLevel
  /**
   *
   * @type {CapacityScores}
   * @memberof PrescriptionDto
   */
  capacityScores?: CapacityScores
  /**
   *
   * @type {Array<PrescriptionPhaseDto>}
   * @memberof PrescriptionDto
   */
  phases?: Array<PrescriptionPhaseDto>
}
/**
 *
 * @export
 * @interface PrescriptionPhaseDto
 */
export interface PrescriptionPhaseDto {
  /**
   *
   * @type {number}
   * @memberof PrescriptionPhaseDto
   */
  number?: number
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof PrescriptionPhaseDto
   */
  capacityMinutes?: CapacityMinutesDto
}
/**
 *
 * @export
 * @enum {string}
 */

export const ResponseCode = {
  Ok: "OK",
  InUse: "IN_USE",
  InvalidInput: "INVALID_INPUT",
  Timeout: "TIMEOUT",
} as const

export type ResponseCode = typeof ResponseCode[keyof typeof ResponseCode]

/**
 *
 * @export
 * @interface SaveExternalWorkoutReqDto
 */
export interface SaveExternalWorkoutReqDto {
  /**
   *
   * @type {string}
   * @memberof SaveExternalWorkoutReqDto
   */
  start: string
  /**
   *
   * @type {string}
   * @memberof SaveExternalWorkoutReqDto
   */
  stop: string
  /**
   *
   * @type {string}
   * @memberof SaveExternalWorkoutReqDto
   */
  name: string
  /**
   *
   * @type {DurationDto}
   * @memberof SaveExternalWorkoutReqDto
   */
  strengthDuration?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof SaveExternalWorkoutReqDto
   */
  enduranceDuration?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof SaveExternalWorkoutReqDto
   */
  balanceDuration?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof SaveExternalWorkoutReqDto
   */
  flexibilityDuration?: DurationDto
}
/**
 *
 * @export
 * @interface SeriesEffort
 */
export interface SeriesEffort {
  /**
   *
   * @type {DurationDto}
   * @memberof SeriesEffort
   */
  estimatedDuration?: DurationDto
  /**
   *
   * @type {number}
   * @memberof SeriesEffort
   */
  count?: number
  /**
   *
   * @type {DurationDto}
   * @memberof SeriesEffort
   */
  effortPerRepetition?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof SeriesEffort
   */
  recoveryBetweenRepetitions?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof SeriesEffort
   */
  afterEffortRecovery?: DurationDto
}
/**
 *
 * @export
 * @interface SetRepEffort
 */
export interface SetRepEffort {
  /**
   *
   * @type {DurationDto}
   * @memberof SetRepEffort
   */
  estimatedDuration?: DurationDto
  /**
   *
   * @type {number}
   * @memberof SetRepEffort
   */
  sets?: number
  /**
   *
   * @type {number}
   * @memberof SetRepEffort
   */
  repetitions?: number
  /**
   *
   * @type {number}
   * @memberof SetRepEffort
   */
  weight?: number
  /**
   *
   * @type {DurationDto}
   * @memberof SetRepEffort
   */
  recoveryBetweenSets?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof SetRepEffort
   */
  afterEffortRecovery?: DurationDto
}
/**
 *
 * @export
 * @interface SetRepResultDto
 */
export interface SetRepResultDto {
  /**
   *
   * @type {number}
   * @memberof SetRepResultDto
   */
  repetitions?: number
  /**
   *
   * @type {number}
   * @memberof SetRepResultDto
   */
  weight?: number
  /**
   *
   * @type {BodySide}
   * @memberof SetRepResultDto
   */
  side?: BodySide
}
/**
 *
 * @export
 * @interface SignUpRequestDto
 */
export interface SignUpRequestDto {
  /**
   *
   * @type {string}
   * @memberof SignUpRequestDto
   * @deprecated
   */
  phoneId?: string
  /**
   *
   * @type {Gender}
   * @memberof SignUpRequestDto
   */
  gender: Gender
  /**
   *
   * @type {string}
   * @memberof SignUpRequestDto
   */
  dateOfBirth?: string
  /**
   *
   * @type {Age}
   * @memberof SignUpRequestDto
   */
  age: Age
  /**
   *
   * @type {ActivityLevel}
   * @memberof SignUpRequestDto
   */
  activityLevel: ActivityLevel
  /**
   *
   * @type {boolean}
   * @memberof SignUpRequestDto
   */
  injuries?: boolean
  /**
   *
   * @type {string}
   * @memberof SignUpRequestDto
   */
  name?: string
  /**
   *
   * @type {AssessmentDto}
   * @memberof SignUpRequestDto
   */
  assessment: AssessmentDto
}
/**
 *
 * @export
 * @interface SignUpResponseDto
 */
export interface SignUpResponseDto {
  /**
   *
   * @type {string}
   * @memberof SignUpResponseDto
   */
  token?: string
  /**
   *
   * @type {PersonDto}
   * @memberof SignUpResponseDto
   */
  person?: PersonDto
  /**
   *
   * @type {SubscriptionDto}
   * @memberof SignUpResponseDto
   */
  subscription?: SubscriptionDto
  /**
   *
   * @type {HealthProfileDto}
   * @memberof SignUpResponseDto
   */
  healthProfile?: HealthProfileDto
}
/**
 *
 * @export
 * @interface StartEmailAuthenticationReqDto
 */
export interface StartEmailAuthenticationReqDto {
  /**
   *
   * @type {string}
   * @memberof StartEmailAuthenticationReqDto
   */
  deviceToken?: string
  /**
   *
   * @type {string}
   * @memberof StartEmailAuthenticationReqDto
   */
  email?: string
}
/**
 *
 * @export
 * @interface StartEmailAuthenticationResDto
 */
export interface StartEmailAuthenticationResDto {
  /**
   *
   * @type {ResponseCode}
   * @memberof StartEmailAuthenticationResDto
   */
  code?: ResponseCode
}
/**
 *
 * @export
 * @interface StartPasswordRecoveryReqDto
 */
export interface StartPasswordRecoveryReqDto {
  /**
   *
   * @type {string}
   * @memberof StartPasswordRecoveryReqDto
   */
  email?: string
}
/**
 *
 * @export
 * @interface StartPeriodRequestDto
 */
export interface StartPeriodRequestDto {
  /**
   *
   * @type {string}
   * @memberof StartPeriodRequestDto
   */
  start?: string
}
/**
 *
 * @export
 * @interface StartVippsSubscriptionReqDto
 */
export interface StartVippsSubscriptionReqDto {
  /**
   *
   * @type {string}
   * @memberof StartVippsSubscriptionReqDto
   */
  phoneNumber: string
}
/**
 *
 * @export
 * @interface StartVippsSubscriptionResDto
 */
export interface StartVippsSubscriptionResDto {
  /**
   *
   * @type {string}
   * @memberof StartVippsSubscriptionResDto
   */
  agreementId?: string
  /**
   *
   * @type {string}
   * @memberof StartVippsSubscriptionResDto
   */
  vippsDeepLinkUrl?: string
}
/**
 *
 * @export
 * @interface StrengthProgram
 */
export interface StrengthProgram {
  /**
   *
   * @type {string}
   * @memberof StrengthProgram
   */
  cellId?: string
  /**
   *
   * @type {CellDuration}
   * @memberof StrengthProgram
   */
  cellDuration?: CellDuration
  /**
   *
   * @type {DurationDto}
   * @memberof StrengthProgram
   */
  duration?: DurationDto
  /**
   *
   * @type {Circuit}
   * @memberof StrengthProgram
   */
  circuit?: Circuit
  /**
   *
   * @type {Array<StrengthProgramStep>}
   * @memberof StrengthProgram
   */
  steps?: Array<StrengthProgramStep>
}
/**
 *
 * @export
 * @interface StrengthProgramStep
 */
export interface StrengthProgramStep {
  /**
   *
   * @type {number}
   * @memberof StrengthProgramStep
   */
  stepNumber?: number
  /**
   *
   * @type {BodyPart}
   * @memberof StrengthProgramStep
   */
  bodyPart?: BodyPart
  /**
   *
   * @type {object}
   * @memberof StrengthProgramStep
   */
  effort?: object
}
/**
 *
 * @export
 * @interface SubscriptionDto
 */
export interface SubscriptionDto {
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  planCode?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  planName?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  paymentProvider?: string
  /**
   *
   * @type {BillingInterval}
   * @memberof SubscriptionDto
   */
  billingInterval?: BillingInterval
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  startDate?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  endDate?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  usageEndDate?: string
  /**
   *
   * @type {string}
   * @memberof SubscriptionDto
   */
  nextChargeDate?: string
  /**
   *
   * @type {number}
   * @memberof SubscriptionDto
   */
  nextChargePrice?: number
  /**
   *
   * @type {boolean}
   * @memberof SubscriptionDto
   */
  paymentProblems?: boolean
}
/**
 *
 * @export
 * @interface SystemStatusResponse
 */
export interface SystemStatusResponse {
  /**
   *
   * @type {string}
   * @memberof SystemStatusResponse
   */
  version?: string
  /**
   *
   * @type {string}
   * @memberof SystemStatusResponse
   */
  bootTime?: string
  /**
   *
   * @type {string}
   * @memberof SystemStatusResponse
   */
  exerciseSearchSettings?: string
}
/**
 *
 * @export
 * @enum {string}
 */

export const TestType = {
  Default: "DEFAULT",
  SelfTest: "SELF_TEST",
  VimScoreTest: "VIM_SCORE_TEST",
} as const

export type TestType = typeof TestType[keyof typeof TestType]

/**
 *
 * @export
 * @interface TimeEffort
 */
export interface TimeEffort {
  /**
   *
   * @type {DurationDto}
   * @memberof TimeEffort
   */
  effort?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof TimeEffort
   */
  afterEffortRecovery?: DurationDto
}
/**
 *
 * @export
 * @interface ValidateVippsSubscriptionReqDto
 */
export interface ValidateVippsSubscriptionReqDto {
  /**
   *
   * @type {string}
   * @memberof ValidateVippsSubscriptionReqDto
   */
  agreementId: string
}
/**
 *
 * @export
 * @interface ValidateVippsSubscriptionResDto
 */
export interface ValidateVippsSubscriptionResDto {
  /**
   *
   * @type {SubscriptionDto}
   * @memberof ValidateVippsSubscriptionResDto
   */
  subscription?: SubscriptionDto
}
/**
 *
 * @export
 * @interface VimPeriodDto
 */
export interface VimPeriodDto {
  /**
   *
   * @type {string}
   * @memberof VimPeriodDto
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof VimPeriodDto
   */
  start?: string
  /**
   *
   * @type {string}
   * @memberof VimPeriodDto
   */
  completed?: string
  /**
   *
   * @type {string}
   * @memberof VimPeriodDto
   */
  cancelled?: string
  /**
   *
   * @type {number}
   * @memberof VimPeriodDto
   * @deprecated
   */
  phaseNumber?: number
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof VimPeriodDto
   */
  prescriptionDoneMinutes?: CapacityMinutesDto
  /**
   *
   * @type {Array<VimWeekDto>}
   * @memberof VimPeriodDto
   */
  weeks?: Array<VimWeekDto>
}
/**
 *
 * @export
 * @interface VimWeekDto
 */
export interface VimWeekDto {
  /**
   *
   * @type {string}
   * @memberof VimWeekDto
   */
  start?: string
  /**
   *
   * @type {string}
   * @memberof VimWeekDto
   */
  stop?: string
  /**
   *
   * @type {string}
   * @memberof VimWeekDto
   * @deprecated
   */
  end?: string
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof VimWeekDto
   */
  done?: CapacityMinutesDto
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof VimWeekDto
   */
  goal?: CapacityMinutesDto
}
/**
 *
 * @export
 * @interface VisExerciseDto
 */
export interface VisExerciseDto {
  /**
   *
   * @type {string}
   * @memberof VisExerciseDto
   */
  externalId?: string
  /**
   *
   * @type {string}
   * @memberof VisExerciseDto
   */
  objectId?: string
  /**
   *
   * @type {boolean}
   * @memberof VisExerciseDto
   */
  approved?: boolean
  /**
   *
   * @type {boolean}
   * @memberof VisExerciseDto
   */
  gym?: boolean
  /**
   *
   * @type {boolean}
   * @memberof VisExerciseDto
   */
  outdoor?: boolean
  /**
   *
   * @type {boolean}
   * @memberof VisExerciseDto
   */
  home?: boolean
  /**
   *
   * @type {string}
   * @memberof VisExerciseDto
   */
  capacity?: string
  /**
   *
   * @type {boolean}
   * @memberof VisExerciseDto
   */
  capacityLevelBasic?: boolean
  /**
   *
   * @type {boolean}
   * @memberof VisExerciseDto
   */
  capacityLevelActive?: boolean
  /**
   *
   * @type {boolean}
   * @memberof VisExerciseDto
   */
  capacityLevelSporty?: boolean
  /**
   *
   * @type {boolean}
   * @memberof VisExerciseDto
   */
  capacityLevelExtreme?: boolean
}
/**
 *
 * @export
 * @interface WhoAmIResponseDto
 */
export interface WhoAmIResponseDto {
  /**
   *
   * @type {PersonDto}
   * @memberof WhoAmIResponseDto
   */
  person?: PersonDto
  /**
   *
   * @type {SubscriptionDto}
   * @memberof WhoAmIResponseDto
   */
  subscription?: SubscriptionDto
  /**
   *
   * @type {HealthProfileDto}
   * @memberof WhoAmIResponseDto
   */
  healthProfile?: HealthProfileDto
  /**
   *
   * @type {Array<ChallengeDto>}
   * @memberof WhoAmIResponseDto
   */
  challenges?: Array<ChallengeDto>
}
/**
 *
 * @export
 * @interface WorkoutDto
 */
export interface WorkoutDto {
  /**
   *
   * @type {string}
   * @memberof WorkoutDto
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof WorkoutDto
   */
  createdDate?: string
  /**
   *
   * @type {DurationDto}
   * @memberof WorkoutDto
   */
  requestedDuration?: DurationDto
  /**
   *
   * @type {Array<WorkoutExerciseGroupDto>}
   * @memberof WorkoutDto
   */
  groups?: Array<WorkoutExerciseGroupDto>
  /**
   *
   * @type {Array<ExerciseDescriptionDto>}
   * @memberof WorkoutDto
   */
  exerciseDescriptions?: Array<ExerciseDescriptionDto>
}
/**
 *
 * @export
 * @interface WorkoutExerciseDto
 */
export interface WorkoutExerciseDto {
  /**
   *
   * @type {string}
   * @memberof WorkoutExerciseDto
   */
  uuid?: string
  /**
   *
   * @type {string}
   * @memberof WorkoutExerciseDto
   * @deprecated
   */
  name?: string
  /**
   *
   * @type {string}
   * @memberof WorkoutExerciseDto
   * @deprecated
   */
  description?: string
  /**
   *
   * @type {ExerciseStyle}
   * @memberof WorkoutExerciseDto
   */
  style?: ExerciseStyle
  /**
   *
   * @type {Capacity}
   * @memberof WorkoutExerciseDto
   */
  capacity?: Capacity
  /**
   *
   * @type {WorkoutPart}
   * @memberof WorkoutExerciseDto
   */
  part?: WorkoutPart
  /**
   *
   * @type {LateralType}
   * @memberof WorkoutExerciseDto
   */
  lateralType?: LateralType
  /**
   *
   * @type {BodyPart}
   * @memberof WorkoutExerciseDto
   */
  bodyPart?: BodyPart
  /**
   *
   * @type {DurationDto}
   * @memberof WorkoutExerciseDto
   */
  duration?: DurationDto
  /**
   *
   * @type {number}
   * @memberof WorkoutExerciseDto
   */
  sets?: number
  /**
   *
   * @type {number}
   * @memberof WorkoutExerciseDto
   */
  repetitions?: number
  /**
   *
   * @type {number}
   * @memberof WorkoutExerciseDto
   */
  weight?: number
  /**
   *
   * @type {DurationDto}
   * @memberof WorkoutExerciseDto
   */
  timeEffort?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof WorkoutExerciseDto
   */
  durationPerRepetition?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof WorkoutExerciseDto
   */
  effortPerRepetition?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof WorkoutExerciseDto
   */
  recoveryBetweenRepetitions?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof WorkoutExerciseDto
   */
  recoveryBetweenSets?: DurationDto
  /**
   *
   * @type {DurationDto}
   * @memberof WorkoutExerciseDto
   */
  recoveryAfterEffort?: DurationDto
  /**
   *
   * @type {string}
   * @memberof WorkoutExerciseDto
   * @deprecated
   */
  animationUrl?: string
  /**
   *
   * @type {string}
   * @memberof WorkoutExerciseDto
   * @deprecated
   */
  thumbnailUrl?: string
  /**
   *
   * @type {Array<string>}
   * @memberof WorkoutExerciseDto
   */
  alternatives?: Array<string>
}
/**
 *
 * @export
 * @interface WorkoutExerciseGroupDto
 */
export interface WorkoutExerciseGroupDto {
  /**
   *
   * @type {number}
   * @memberof WorkoutExerciseGroupDto
   */
  repetitions?: number
  /**
   *
   * @type {Array<WorkoutExerciseDto>}
   * @memberof WorkoutExerciseGroupDto
   */
  exercises?: Array<WorkoutExerciseDto>
}
/**
 *
 * @export
 * @interface WorkoutExerciseResultDto
 */
export interface WorkoutExerciseResultDto {
  /**
   *
   * @type {string}
   * @memberof WorkoutExerciseResultDto
   * @deprecated
   */
  exercise?: string
  /**
   *
   * @type {string}
   * @memberof WorkoutExerciseResultDto
   */
  workoutExercise?: string
  /**
   *
   * @type {string}
   * @memberof WorkoutExerciseResultDto
   */
  exerciseDescription?: string
  /**
   *
   * @type {Capacity}
   * @memberof WorkoutExerciseResultDto
   */
  capacity: Capacity
  /**
   *
   * @type {boolean}
   * @memberof WorkoutExerciseResultDto
   */
  completed: boolean
  /**
   *
   * @type {DurationDto}
   * @memberof WorkoutExerciseResultDto
   */
  timer?: DurationDto
  /**
   *
   * @type {Array<SetRepResultDto>}
   * @memberof WorkoutExerciseResultDto
   */
  setReps: Array<SetRepResultDto>
}
/**
 *
 * @export
 * @enum {string}
 */

export const WorkoutPart = {
  WarmUp: "WARM_UP",
  Main: "MAIN",
  CoolDown: "COOL_DOWN",
} as const

export type WorkoutPart = typeof WorkoutPart[keyof typeof WorkoutPart]

/**
 *
 * @export
 * @interface WorkoutResultDto
 */
export interface WorkoutResultDto {
  /**
   *
   * @type {string}
   * @memberof WorkoutResultDto
   */
  workout: string
  /**
   *
   * @type {string}
   * @memberof WorkoutResultDto
   */
  start: string
  /**
   *
   * @type {string}
   * @memberof WorkoutResultDto
   */
  stop: string
  /**
   *
   * @type {Array<WorkoutExerciseResultDto>}
   * @memberof WorkoutResultDto
   */
  exercises: Array<WorkoutExerciseResultDto>
}
/**
 *
 * @export
 * @interface WorkoutSpecification
 */
export interface WorkoutSpecification {
  /**
   *
   * @type {Array<string>}
   * @memberof WorkoutSpecification
   */
  log?: Array<string>
  /**
   *
   * @type {DurationDto}
   * @memberof WorkoutSpecification
   */
  availableTime?: DurationDto
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof WorkoutSpecification
   */
  inputMinutes?: CapacityMinutesDto
  /**
   *
   * @type {CapacityLevels}
   * @memberof WorkoutSpecification
   */
  capacityLevels?: CapacityLevels
  /**
   *
   * @type {PartSpecification}
   * @memberof WorkoutSpecification
   */
  warmUp?: PartSpecification
  /**
   *
   * @type {PartSpecification}
   * @memberof WorkoutSpecification
   */
  main?: PartSpecification
  /**
   *
   * @type {PartSpecification}
   * @memberof WorkoutSpecification
   */
  coolDown?: PartSpecification
}
/**
 *
 * @export
 * @interface WorkoutSpecificationDto
 */
export interface WorkoutSpecificationDto {
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof WorkoutSpecificationDto
   */
  warmUp?: CapacityMinutesDto
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof WorkoutSpecificationDto
   */
  main?: CapacityMinutesDto
  /**
   *
   * @type {CapacityMinutesDto}
   * @memberof WorkoutSpecificationDto
   */
  coolDown?: CapacityMinutesDto
}

/**
 * ActivityControllerApi - axios parameter creator
 * @export
 */
export const ActivityControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} activitySession
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivity: async (activitySession: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'activitySession' is not null or undefined
      assertParamExists("getActivity", "activitySession", activitySession)
      const localVarPath = `/activity/{activitySession}`.replace(
        `{${"activitySession"}}`,
        encodeURIComponent(String(activitySession))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityCalendar: async (
      person: string,
      from: string,
      to: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("getActivityCalendar", "person", person)
      // verify required parameter 'from' is not null or undefined
      assertParamExists("getActivityCalendar", "from", from)
      // verify required parameter 'to' is not null or undefined
      assertParamExists("getActivityCalendar", "to", to)
      const localVarPath = `/person/{person}/activity`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (from !== undefined) {
        localVarQueryParameter["from"] =
          (from as any) instanceof Date ? (from as any).toISOString().substr(0, 10) : from
      }

      if (to !== undefined) {
        localVarQueryParameter["to"] = (to as any) instanceof Date ? (to as any).toISOString().substr(0, 10) : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getActivityCalendarOld: async (
      from: string,
      to: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'from' is not null or undefined
      assertParamExists("getActivityCalendarOld", "from", from)
      // verify required parameter 'to' is not null or undefined
      assertParamExists("getActivityCalendarOld", "to", to)
      const localVarPath = `/activity`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (from !== undefined) {
        localVarQueryParameter["from"] =
          (from as any) instanceof Date ? (from as any).toISOString().substr(0, 10) : from
      }

      if (to !== undefined) {
        localVarQueryParameter["to"] = (to as any) instanceof Date ? (to as any).toISOString().substr(0, 10) : to
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {string} yearWeek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonWeek: async (person: string, yearWeek: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("getPersonWeek", "person", person)
      // verify required parameter 'yearWeek' is not null or undefined
      assertParamExists("getPersonWeek", "yearWeek", yearWeek)
      const localVarPath = `/person/{person}/week/{yearWeek}`
        .replace(`{${"person"}}`, encodeURIComponent(String(person)))
        .replace(`{${"yearWeek"}}`, encodeURIComponent(String(yearWeek)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ActivityControllerApi - functional programming interface
 * @export
 */
export const ActivityControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ActivityControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} activitySession
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActivity(
      activitySession: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySessionDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActivity(activitySession, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActivityCalendar(
      person: string,
      from: string,
      to: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivitySessionDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityCalendar(person, from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getActivityCalendarOld(
      from: string,
      to: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivitySessionDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActivityCalendarOld(from, to, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {string} yearWeek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPersonWeek(
      person: string,
      yearWeek: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonWeekDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonWeek(person, yearWeek, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ActivityControllerApi - factory interface
 * @export
 */
export const ActivityControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ActivityControllerApiFp(configuration)
  return {
    /**
     *
     * @param {string} activitySession
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivity(activitySession: string, options?: any): AxiosPromise<ActivitySessionDto> {
      return localVarFp.getActivity(activitySession, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivityCalendar(
      person: string,
      from: string,
      to: string,
      options?: any
    ): AxiosPromise<Array<ActivitySessionDto>> {
      return localVarFp.getActivityCalendar(person, from, to, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} from
     * @param {string} to
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getActivityCalendarOld(from: string, to: string, options?: any): AxiosPromise<Array<ActivitySessionDto>> {
      return localVarFp.getActivityCalendarOld(from, to, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {string} yearWeek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPersonWeek(person: string, yearWeek: string, options?: any): AxiosPromise<PersonWeekDto> {
      return localVarFp.getPersonWeek(person, yearWeek, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * ActivityControllerApi - object-oriented interface
 * @export
 * @class ActivityControllerApi
 * @extends {BaseAPI}
 */
export class ActivityControllerApi extends BaseAPI {
  /**
   *
   * @param {string} activitySession
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityControllerApi
   */
  public getActivity(activitySession: string, options?: AxiosRequestConfig) {
    return ActivityControllerApiFp(this.configuration)
      .getActivity(activitySession, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {string} from
   * @param {string} to
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityControllerApi
   */
  public getActivityCalendar(person: string, from: string, to: string, options?: AxiosRequestConfig) {
    return ActivityControllerApiFp(this.configuration)
      .getActivityCalendar(person, from, to, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} from
   * @param {string} to
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof ActivityControllerApi
   */
  public getActivityCalendarOld(from: string, to: string, options?: AxiosRequestConfig) {
    return ActivityControllerApiFp(this.configuration)
      .getActivityCalendarOld(from, to, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {string} yearWeek
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ActivityControllerApi
   */
  public getPersonWeek(person: string, yearWeek: string, options?: AxiosRequestConfig) {
    return ActivityControllerApiFp(this.configuration)
      .getPersonWeek(person, yearWeek, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * AuthenticationApi - axios parameter creator
 * @export
 */
export const AuthenticationApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {AuthenticationRequestDto} authenticationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticate: async (
      authenticationRequestDto: AuthenticationRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'authenticationRequestDto' is not null or undefined
      assertParamExists("authenticate", "authenticationRequestDto", authenticationRequestDto)
      const localVarPath = `/auth/authenticate`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        authenticationRequestDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ContinuePasswordRecoveryReqDto} continuePasswordRecoveryReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    continuePasswordRecovery: async (
      continuePasswordRecoveryReqDto: ContinuePasswordRecoveryReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'continuePasswordRecoveryReqDto' is not null or undefined
      assertParamExists("continuePasswordRecovery", "continuePasswordRecoveryReqDto", continuePasswordRecoveryReqDto)
      const localVarPath = `/auth/password-recovery/continue`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        continuePasswordRecoveryReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {StartPasswordRecoveryReqDto} startPasswordRecoveryReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startPasswordRecovery: async (
      startPasswordRecoveryReqDto: StartPasswordRecoveryReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startPasswordRecoveryReqDto' is not null or undefined
      assertParamExists("startPasswordRecovery", "startPasswordRecoveryReqDto", startPasswordRecoveryReqDto)
      const localVarPath = `/auth/password-recovery/start`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        startPasswordRecoveryReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * AuthenticationApi - functional programming interface
 * @export
 */
export const AuthenticationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AuthenticationApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {AuthenticationRequestDto} authenticationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async authenticate(
      authenticationRequestDto: AuthenticationRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AuthenticationResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.authenticate(authenticationRequestDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ContinuePasswordRecoveryReqDto} continuePasswordRecoveryReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async continuePasswordRecovery(
      continuePasswordRecoveryReqDto: ContinuePasswordRecoveryReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.continuePasswordRecovery(
        continuePasswordRecoveryReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {StartPasswordRecoveryReqDto} startPasswordRecoveryReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startPasswordRecovery(
      startPasswordRecoveryReqDto: StartPasswordRecoveryReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startPasswordRecovery(
        startPasswordRecoveryReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * AuthenticationApi - factory interface
 * @export
 */
export const AuthenticationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = AuthenticationApiFp(configuration)
  return {
    /**
     *
     * @param {AuthenticationRequestDto} authenticationRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    authenticate(
      authenticationRequestDto: AuthenticationRequestDto,
      options?: any
    ): AxiosPromise<AuthenticationResponseDto> {
      return localVarFp.authenticate(authenticationRequestDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ContinuePasswordRecoveryReqDto} continuePasswordRecoveryReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    continuePasswordRecovery(
      continuePasswordRecoveryReqDto: ContinuePasswordRecoveryReqDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .continuePasswordRecovery(continuePasswordRecoveryReqDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {StartPasswordRecoveryReqDto} startPasswordRecoveryReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startPasswordRecovery(startPasswordRecoveryReqDto: StartPasswordRecoveryReqDto, options?: any): AxiosPromise<void> {
      return localVarFp
        .startPasswordRecovery(startPasswordRecoveryReqDto, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * AuthenticationApi - object-oriented interface
 * @export
 * @class AuthenticationApi
 * @extends {BaseAPI}
 */
export class AuthenticationApi extends BaseAPI {
  /**
   *
   * @param {AuthenticationRequestDto} authenticationRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public authenticate(authenticationRequestDto: AuthenticationRequestDto, options?: AxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .authenticate(authenticationRequestDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ContinuePasswordRecoveryReqDto} continuePasswordRecoveryReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public continuePasswordRecovery(
    continuePasswordRecoveryReqDto: ContinuePasswordRecoveryReqDto,
    options?: AxiosRequestConfig
  ) {
    return AuthenticationApiFp(this.configuration)
      .continuePasswordRecovery(continuePasswordRecoveryReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {StartPasswordRecoveryReqDto} startPasswordRecoveryReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AuthenticationApi
   */
  public startPasswordRecovery(startPasswordRecoveryReqDto: StartPasswordRecoveryReqDto, options?: AxiosRequestConfig) {
    return AuthenticationApiFp(this.configuration)
      .startPasswordRecovery(startPasswordRecoveryReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ChallengeApi - axios parameter creator
 * @export
 */
export const ChallengeApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateChallengeReqDto} createChallengeReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createChallenge: async (
      createChallengeReqDto: CreateChallengeReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createChallengeReqDto' is not null or undefined
      assertParamExists("createChallenge", "createChallengeReqDto", createChallengeReqDto)
      const localVarPath = `/challenge/service/create-challenge`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(createChallengeReqDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateChallengeTeamReqDto} createChallengeTeamReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTeam: async (
      createChallengeTeamReqDto: CreateChallengeTeamReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createChallengeTeamReqDto' is not null or undefined
      assertParamExists("createTeam", "createChallengeTeamReqDto", createChallengeTeamReqDto)
      const localVarPath = `/challenge/service/create-team`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createChallengeTeamReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} challenge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteChallenge: async (challenge: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'challenge' is not null or undefined
      assertParamExists("deleteChallenge", "challenge", challenge)
      const localVarPath = `/challenge/details/{challenge}`.replace(
        `{${"challenge"}}`,
        encodeURIComponent(String(challenge))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} challenge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChallengeDetails: async (challenge: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'challenge' is not null or undefined
      assertParamExists("getChallengeDetails", "challenge", challenge)
      const localVarPath = `/challenge/details/{challenge}`.replace(
        `{${"challenge"}}`,
        encodeURIComponent(String(challenge))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} challenge
     * @param {ChallengeReportReqDto} challengeReportReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChallengeReport: async (
      challenge: string,
      challengeReportReqDto: ChallengeReportReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'challenge' is not null or undefined
      assertParamExists("getChallengeReport", "challenge", challenge)
      // verify required parameter 'challengeReportReqDto' is not null or undefined
      assertParamExists("getChallengeReport", "challengeReportReqDto", challengeReportReqDto)
      const localVarPath = `/challenge/details/{challenge}/report`.replace(
        `{${"challenge"}}`,
        encodeURIComponent(String(challenge))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(challengeReportReqDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} challenge
     * @param {string} yearWeek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChallengeWeek: async (
      challenge: string,
      yearWeek: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'challenge' is not null or undefined
      assertParamExists("getChallengeWeek", "challenge", challenge)
      // verify required parameter 'yearWeek' is not null or undefined
      assertParamExists("getChallengeWeek", "yearWeek", yearWeek)
      const localVarPath = `/challenge/week/{challenge}/{yearWeek}`
        .replace(`{${"challenge"}}`, encodeURIComponent(String(challenge)))
        .replace(`{${"yearWeek"}}`, encodeURIComponent(String(yearWeek)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {boolean} [includeAll]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChallenges: async (includeAll?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/challenge/challenge`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (includeAll !== undefined) {
        localVarQueryParameter["includeAll"] = includeAll
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {JoinChallengeReqDto} joinChallengeReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    joinChallenge: async (
      joinChallengeReqDto: JoinChallengeReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'joinChallengeReqDto' is not null or undefined
      assertParamExists("joinChallenge", "joinChallengeReqDto", joinChallengeReqDto)
      const localVarPath = `/challenge/service/join-challenge`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(joinChallengeReqDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {LeaveChallengeReqDto} leaveChallengeReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leaveChallenge: async (
      leaveChallengeReqDto: LeaveChallengeReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'leaveChallengeReqDto' is not null or undefined
      assertParamExists("leaveChallenge", "leaveChallengeReqDto", leaveChallengeReqDto)
      const localVarPath = `/challenge/service/leave-challenge`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(leaveChallengeReqDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} challenge
     * @param {string} person
     * @param {ChallengeMemberDto} challengeMemberDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMember: async (
      challenge: string,
      person: string,
      challengeMemberDto: ChallengeMemberDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'challenge' is not null or undefined
      assertParamExists("updateMember", "challenge", challenge)
      // verify required parameter 'person' is not null or undefined
      assertParamExists("updateMember", "person", person)
      // verify required parameter 'challengeMemberDto' is not null or undefined
      assertParamExists("updateMember", "challengeMemberDto", challengeMemberDto)
      const localVarPath = `/challenge/details/{challenge}/members/{person}`
        .replace(`{${"challenge"}}`, encodeURIComponent(String(challenge)))
        .replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(challengeMemberDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} team
     * @param {ChallengeTeamDto} challengeTeamDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTeam: async (
      team: string,
      challengeTeamDto: ChallengeTeamDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'team' is not null or undefined
      assertParamExists("updateTeam", "team", team)
      // verify required parameter 'challengeTeamDto' is not null or undefined
      assertParamExists("updateTeam", "challengeTeamDto", challengeTeamDto)
      const localVarPath = `/challenge/team/{team}`.replace(`{${"team"}}`, encodeURIComponent(String(team)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(challengeTeamDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ChallengeApi - functional programming interface
 * @export
 */
export const ChallengeApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ChallengeApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CreateChallengeReqDto} createChallengeReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createChallenge(
      createChallengeReqDto: CreateChallengeReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeDetailsDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createChallenge(createChallengeReqDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateChallengeTeamReqDto} createChallengeTeamReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createTeam(
      createChallengeTeamReqDto: CreateChallengeTeamReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeDetailsDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createTeam(createChallengeTeamReqDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} challenge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async deleteChallenge(
      challenge: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.deleteChallenge(challenge, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} challenge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getChallengeDetails(
      challenge: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeDetailsDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getChallengeDetails(challenge, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} challenge
     * @param {ChallengeReportReqDto} challengeReportReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getChallengeReport(
      challenge: string,
      challengeReportReqDto: ChallengeReportReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getChallengeReport(
        challenge,
        challengeReportReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} challenge
     * @param {string} yearWeek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getChallengeWeek(
      challenge: string,
      yearWeek: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeWeekDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getChallengeWeek(challenge, yearWeek, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {boolean} [includeAll]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getChallenges(
      includeAll?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ChallengeDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getChallenges(includeAll, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {JoinChallengeReqDto} joinChallengeReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async joinChallenge(
      joinChallengeReqDto: JoinChallengeReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.joinChallenge(joinChallengeReqDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {LeaveChallengeReqDto} leaveChallengeReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async leaveChallenge(
      leaveChallengeReqDto: LeaveChallengeReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.leaveChallenge(leaveChallengeReqDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} challenge
     * @param {string} person
     * @param {ChallengeMemberDto} challengeMemberDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateMember(
      challenge: string,
      person: string,
      challengeMemberDto: ChallengeMemberDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeDetailsDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateMember(
        challenge,
        person,
        challengeMemberDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} team
     * @param {ChallengeTeamDto} challengeTeamDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updateTeam(
      team: string,
      challengeTeamDto: ChallengeTeamDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChallengeDetailsDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updateTeam(team, challengeTeamDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ChallengeApi - factory interface
 * @export
 */
export const ChallengeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ChallengeApiFp(configuration)
  return {
    /**
     *
     * @param {CreateChallengeReqDto} createChallengeReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createChallenge(createChallengeReqDto: CreateChallengeReqDto, options?: any): AxiosPromise<ChallengeDetailsDto> {
      return localVarFp.createChallenge(createChallengeReqDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateChallengeTeamReqDto} createChallengeTeamReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createTeam(createChallengeTeamReqDto: CreateChallengeTeamReqDto, options?: any): AxiosPromise<ChallengeDetailsDto> {
      return localVarFp.createTeam(createChallengeTeamReqDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} challenge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    deleteChallenge(challenge: string, options?: any): AxiosPromise<void> {
      return localVarFp.deleteChallenge(challenge, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} challenge
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChallengeDetails(challenge: string, options?: any): AxiosPromise<ChallengeDetailsDto> {
      return localVarFp.getChallengeDetails(challenge, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} challenge
     * @param {ChallengeReportReqDto} challengeReportReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChallengeReport(
      challenge: string,
      challengeReportReqDto: ChallengeReportReqDto,
      options?: any
    ): AxiosPromise<any> {
      return localVarFp
        .getChallengeReport(challenge, challengeReportReqDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} challenge
     * @param {string} yearWeek
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChallengeWeek(challenge: string, yearWeek: string, options?: any): AxiosPromise<ChallengeWeekDto> {
      return localVarFp.getChallengeWeek(challenge, yearWeek, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {boolean} [includeAll]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getChallenges(includeAll?: boolean, options?: any): AxiosPromise<Array<ChallengeDto>> {
      return localVarFp.getChallenges(includeAll, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {JoinChallengeReqDto} joinChallengeReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    joinChallenge(joinChallengeReqDto: JoinChallengeReqDto, options?: any): AxiosPromise<object> {
      return localVarFp.joinChallenge(joinChallengeReqDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {LeaveChallengeReqDto} leaveChallengeReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    leaveChallenge(leaveChallengeReqDto: LeaveChallengeReqDto, options?: any): AxiosPromise<object> {
      return localVarFp.leaveChallenge(leaveChallengeReqDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} challenge
     * @param {string} person
     * @param {ChallengeMemberDto} challengeMemberDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateMember(
      challenge: string,
      person: string,
      challengeMemberDto: ChallengeMemberDto,
      options?: any
    ): AxiosPromise<ChallengeDetailsDto> {
      return localVarFp
        .updateMember(challenge, person, challengeMemberDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} team
     * @param {ChallengeTeamDto} challengeTeamDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updateTeam(team: string, challengeTeamDto: ChallengeTeamDto, options?: any): AxiosPromise<ChallengeDetailsDto> {
      return localVarFp.updateTeam(team, challengeTeamDto, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * ChallengeApi - object-oriented interface
 * @export
 * @class ChallengeApi
 * @extends {BaseAPI}
 */
export class ChallengeApi extends BaseAPI {
  /**
   *
   * @param {CreateChallengeReqDto} createChallengeReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public createChallenge(createChallengeReqDto: CreateChallengeReqDto, options?: AxiosRequestConfig) {
    return ChallengeApiFp(this.configuration)
      .createChallenge(createChallengeReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateChallengeTeamReqDto} createChallengeTeamReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public createTeam(createChallengeTeamReqDto: CreateChallengeTeamReqDto, options?: AxiosRequestConfig) {
    return ChallengeApiFp(this.configuration)
      .createTeam(createChallengeTeamReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} challenge
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public deleteChallenge(challenge: string, options?: AxiosRequestConfig) {
    return ChallengeApiFp(this.configuration)
      .deleteChallenge(challenge, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} challenge
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public getChallengeDetails(challenge: string, options?: AxiosRequestConfig) {
    return ChallengeApiFp(this.configuration)
      .getChallengeDetails(challenge, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} challenge
   * @param {ChallengeReportReqDto} challengeReportReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public getChallengeReport(
    challenge: string,
    challengeReportReqDto: ChallengeReportReqDto,
    options?: AxiosRequestConfig
  ) {
    return ChallengeApiFp(this.configuration)
      .getChallengeReport(challenge, challengeReportReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} challenge
   * @param {string} yearWeek
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public getChallengeWeek(challenge: string, yearWeek: string, options?: AxiosRequestConfig) {
    return ChallengeApiFp(this.configuration)
      .getChallengeWeek(challenge, yearWeek, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {boolean} [includeAll]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public getChallenges(includeAll?: boolean, options?: AxiosRequestConfig) {
    return ChallengeApiFp(this.configuration)
      .getChallenges(includeAll, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {JoinChallengeReqDto} joinChallengeReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public joinChallenge(joinChallengeReqDto: JoinChallengeReqDto, options?: AxiosRequestConfig) {
    return ChallengeApiFp(this.configuration)
      .joinChallenge(joinChallengeReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {LeaveChallengeReqDto} leaveChallengeReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public leaveChallenge(leaveChallengeReqDto: LeaveChallengeReqDto, options?: AxiosRequestConfig) {
    return ChallengeApiFp(this.configuration)
      .leaveChallenge(leaveChallengeReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} challenge
   * @param {string} person
   * @param {ChallengeMemberDto} challengeMemberDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public updateMember(
    challenge: string,
    person: string,
    challengeMemberDto: ChallengeMemberDto,
    options?: AxiosRequestConfig
  ) {
    return ChallengeApiFp(this.configuration)
      .updateMember(challenge, person, challengeMemberDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} team
   * @param {ChallengeTeamDto} challengeTeamDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ChallengeApi
   */
  public updateTeam(team: string, challengeTeamDto: ChallengeTeamDto, options?: AxiosRequestConfig) {
    return ChallengeApiFp(this.configuration)
      .updateTeam(team, challengeTeamDto, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ConsoleApi - axios parameter creator
 * @export
 */
export const ConsoleApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountSearch: async (query?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/web/console-account-search`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (query !== undefined) {
        localVarQueryParameter["query"] = query
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activitiesAndWorkouts: async (person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("activitiesAndWorkouts", "person", person)
      const localVarPath = `/web/{person}/activities-and-workouts`.replace(
        `{${"person"}}`,
        encodeURIComponent(String(person))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ActivityReportRequestDto} activityReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityReport: async (
      activityReportRequestDto: ActivityReportRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'activityReportRequestDto' is not null or undefined
      assertParamExists("activityReport", "activityReportRequestDto", activityReportRequestDto)
      const localVarPath = `/web/report/activity-report`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        activityReportRequestDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {AddPhoneNumberConsoleReqDto} addPhoneNumberConsoleReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPhoneNumber: async (
      person: string,
      addPhoneNumberConsoleReqDto: AddPhoneNumberConsoleReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("addPhoneNumber", "person", person)
      // verify required parameter 'addPhoneNumberConsoleReqDto' is not null or undefined
      assertParamExists("addPhoneNumber", "addPhoneNumberConsoleReqDto", addPhoneNumberConsoleReqDto)
      const localVarPath = `/web/person/{person}/phone-numbers`.replace(
        `{${"person"}}`,
        encodeURIComponent(String(person))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        addPhoneNumberConsoleReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateConsoleAccountReqDto} createConsoleAccountReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createConsoleAccount: async (
      createConsoleAccountReqDto: CreateConsoleAccountReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createConsoleAccountReqDto' is not null or undefined
      assertParamExists("createConsoleAccount", "createConsoleAccountReqDto", createConsoleAccountReqDto)
      const localVarPath = `/web/console-account`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createConsoleAccountReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreatePersonReqDto} createPersonReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPerson: async (
      createPersonReqDto: CreatePersonReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPersonReqDto' is not null or undefined
      assertParamExists("createPerson", "createPersonReqDto", createPersonReqDto)
      const localVarPath = `/web/person`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(createPersonReqDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} exerciseDescriptionIdOr4TuneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnimation: async (
      exerciseDescriptionIdOr4TuneId: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'exerciseDescriptionIdOr4TuneId' is not null or undefined
      assertParamExists("getAnimation", "exerciseDescriptionIdOr4TuneId", exerciseDescriptionIdOr4TuneId)
      const localVarPath = `/web/animations/{exerciseDescriptionIdOr4TuneId}`.replace(
        `{${"exerciseDescriptionIdOr4TuneId"}}`,
        encodeURIComponent(String(exerciseDescriptionIdOr4TuneId))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnimations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/web/animations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsoleAccount: async (id: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'id' is not null or undefined
      assertParamExists("getConsoleAccount", "id", id)
      const localVarPath = `/web/console-account/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(id)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsoleAccounts: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/web/console-account`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listVisExercises: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/web/vis/exercise`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personInfo: async (person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("personInfo", "person", person)
      const localVarPath = `/web/{person}/info`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} challenge
     * @param {string} [yearWeek]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshChallenge: async (
      challenge: string,
      yearWeek?: string,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'challenge' is not null or undefined
      assertParamExists("refreshChallenge", "challenge", challenge)
      const localVarPath = `/web/challenge/{challenge}/refresh`.replace(
        `{${"challenge"}}`,
        encodeURIComponent(String(challenge))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (yearWeek !== undefined) {
        localVarQueryParameter["yearWeek"] = yearWeek
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {number} account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeAccount: async (account: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'account' is not null or undefined
      assertParamExists("removeAccount", "account", account)
      const localVarPath = `/web/console-account/{account}`.replace(
        `{${"account"}}`,
        encodeURIComponent(String(account))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} externalExerciseId
     * @param {number} width
     * @param {number} height
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadLottie: async (
      externalExerciseId: string,
      width: number,
      height: number,
      inlineObject2?: InlineObject2,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'externalExerciseId' is not null or undefined
      assertParamExists("uploadLottie", "externalExerciseId", externalExerciseId)
      // verify required parameter 'width' is not null or undefined
      assertParamExists("uploadLottie", "width", width)
      // verify required parameter 'height' is not null or undefined
      assertParamExists("uploadLottie", "height", height)
      const localVarPath = `/web/animations/upload-lottie`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (externalExerciseId !== undefined) {
        localVarQueryParameter["externalExerciseId"] = externalExerciseId
      }

      if (width !== undefined) {
        localVarQueryParameter["width"] = width
      }

      if (height !== undefined) {
        localVarQueryParameter["height"] = height
      }

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(inlineObject2, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ConsoleApi - functional programming interface
 * @export
 */
export const ConsoleApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ConsoleApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async accountSearch(
      query?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AccountInfo>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.accountSearch(query, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activitiesAndWorkouts(
      person: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ActivityAndWorkout>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.activitiesAndWorkouts(person, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ActivityReportRequestDto} activityReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async activityReport(
      activityReportRequestDto: ActivityReportRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.activityReport(activityReportRequestDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {AddPhoneNumberConsoleReqDto} addPhoneNumberConsoleReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async addPhoneNumber(
      person: string,
      addPhoneNumberConsoleReqDto: AddPhoneNumberConsoleReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.addPhoneNumber(
        person,
        addPhoneNumberConsoleReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateConsoleAccountReqDto} createConsoleAccountReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createConsoleAccount(
      createConsoleAccountReqDto: CreateConsoleAccountReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsoleAccountDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createConsoleAccount(
        createConsoleAccountReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreatePersonReqDto} createPersonReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPerson(
      createPersonReqDto: CreatePersonReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPerson(createPersonReqDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} exerciseDescriptionIdOr4TuneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnimation(
      exerciseDescriptionIdOr4TuneId: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<AnimationDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAnimation(exerciseDescriptionIdOr4TuneId, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getAnimations(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnimationDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getAnimations(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConsoleAccount(
      id: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConsoleAccountDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getConsoleAccount(id, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getConsoleAccounts(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConsoleAccountDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getConsoleAccounts(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async listVisExercises(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VisExerciseDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.listVisExercises(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async personInfo(
      person: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonInfoDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.personInfo(person, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} challenge
     * @param {string} [yearWeek]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async refreshChallenge(
      challenge: string,
      yearWeek?: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.refreshChallenge(challenge, yearWeek, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {number} account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeAccount(
      account: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeAccount(account, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} externalExerciseId
     * @param {number} width
     * @param {number} height
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async uploadLottie(
      externalExerciseId: string,
      width: number,
      height: number,
      inlineObject2?: InlineObject2,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.uploadLottie(
        externalExerciseId,
        width,
        height,
        inlineObject2,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ConsoleApi - factory interface
 * @export
 */
export const ConsoleApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = ConsoleApiFp(configuration)
  return {
    /**
     *
     * @param {string} [query]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    accountSearch(query?: string, options?: any): AxiosPromise<Array<AccountInfo>> {
      return localVarFp.accountSearch(query, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activitiesAndWorkouts(person: string, options?: any): AxiosPromise<Array<ActivityAndWorkout>> {
      return localVarFp.activitiesAndWorkouts(person, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ActivityReportRequestDto} activityReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    activityReport(activityReportRequestDto: ActivityReportRequestDto, options?: any): AxiosPromise<any> {
      return localVarFp.activityReport(activityReportRequestDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {AddPhoneNumberConsoleReqDto} addPhoneNumberConsoleReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    addPhoneNumber(
      person: string,
      addPhoneNumberConsoleReqDto: AddPhoneNumberConsoleReqDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .addPhoneNumber(person, addPhoneNumberConsoleReqDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateConsoleAccountReqDto} createConsoleAccountReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createConsoleAccount(
      createConsoleAccountReqDto: CreateConsoleAccountReqDto,
      options?: any
    ): AxiosPromise<ConsoleAccountDto> {
      return localVarFp
        .createConsoleAccount(createConsoleAccountReqDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreatePersonReqDto} createPersonReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPerson(createPersonReqDto: CreatePersonReqDto, options?: any): AxiosPromise<PersonDto> {
      return localVarFp.createPerson(createPersonReqDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} exerciseDescriptionIdOr4TuneId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnimation(exerciseDescriptionIdOr4TuneId: string, options?: any): AxiosPromise<AnimationDto> {
      return localVarFp
        .getAnimation(exerciseDescriptionIdOr4TuneId, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getAnimations(options?: any): AxiosPromise<Array<AnimationDto>> {
      return localVarFp.getAnimations(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsoleAccount(id: number, options?: any): AxiosPromise<ConsoleAccountDto> {
      return localVarFp.getConsoleAccount(id, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getConsoleAccounts(options?: any): AxiosPromise<Array<ConsoleAccountDto>> {
      return localVarFp.getConsoleAccounts(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    listVisExercises(options?: any): AxiosPromise<Array<VisExerciseDto>> {
      return localVarFp.listVisExercises(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    personInfo(person: string, options?: any): AxiosPromise<PersonInfoDto> {
      return localVarFp.personInfo(person, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} challenge
     * @param {string} [yearWeek]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    refreshChallenge(challenge: string, yearWeek?: string, options?: any): AxiosPromise<JobResponseDto> {
      return localVarFp.refreshChallenge(challenge, yearWeek, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {number} account
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeAccount(account: number, options?: any): AxiosPromise<object> {
      return localVarFp.removeAccount(account, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} externalExerciseId
     * @param {number} width
     * @param {number} height
     * @param {InlineObject2} [inlineObject2]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    uploadLottie(
      externalExerciseId: string,
      width: number,
      height: number,
      inlineObject2?: InlineObject2,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .uploadLottie(externalExerciseId, width, height, inlineObject2, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ConsoleApi - object-oriented interface
 * @export
 * @class ConsoleApi
 * @extends {BaseAPI}
 */
export class ConsoleApi extends BaseAPI {
  /**
   *
   * @param {string} [query]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public accountSearch(query?: string, options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .accountSearch(query, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public activitiesAndWorkouts(person: string, options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .activitiesAndWorkouts(person, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ActivityReportRequestDto} activityReportRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public activityReport(activityReportRequestDto: ActivityReportRequestDto, options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .activityReport(activityReportRequestDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {AddPhoneNumberConsoleReqDto} addPhoneNumberConsoleReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public addPhoneNumber(
    person: string,
    addPhoneNumberConsoleReqDto: AddPhoneNumberConsoleReqDto,
    options?: AxiosRequestConfig
  ) {
    return ConsoleApiFp(this.configuration)
      .addPhoneNumber(person, addPhoneNumberConsoleReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateConsoleAccountReqDto} createConsoleAccountReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public createConsoleAccount(createConsoleAccountReqDto: CreateConsoleAccountReqDto, options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .createConsoleAccount(createConsoleAccountReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreatePersonReqDto} createPersonReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public createPerson(createPersonReqDto: CreatePersonReqDto, options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .createPerson(createPersonReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} exerciseDescriptionIdOr4TuneId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public getAnimation(exerciseDescriptionIdOr4TuneId: string, options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .getAnimation(exerciseDescriptionIdOr4TuneId, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public getAnimations(options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .getAnimations(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public getConsoleAccount(id: number, options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .getConsoleAccount(id, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public getConsoleAccounts(options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .getConsoleAccounts(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public listVisExercises(options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .listVisExercises(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public personInfo(person: string, options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .personInfo(person, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} challenge
   * @param {string} [yearWeek]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public refreshChallenge(challenge: string, yearWeek?: string, options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .refreshChallenge(challenge, yearWeek, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {number} account
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public removeAccount(account: number, options?: AxiosRequestConfig) {
    return ConsoleApiFp(this.configuration)
      .removeAccount(account, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} externalExerciseId
   * @param {number} width
   * @param {number} height
   * @param {InlineObject2} [inlineObject2]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ConsoleApi
   */
  public uploadLottie(
    externalExerciseId: string,
    width: number,
    height: number,
    inlineObject2?: InlineObject2,
    options?: AxiosRequestConfig
  ) {
    return ConsoleApiFp(this.configuration)
      .uploadLottie(externalExerciseId, width, height, inlineObject2, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * ExternalWorkoutApi - axios parameter creator
 * @export
 */
export const ExternalWorkoutApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} person
     * @param {SaveExternalWorkoutReqDto} saveExternalWorkoutReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveExternalWorkout: async (
      person: string,
      saveExternalWorkoutReqDto: SaveExternalWorkoutReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("saveExternalWorkout", "person", person)
      // verify required parameter 'saveExternalWorkoutReqDto' is not null or undefined
      assertParamExists("saveExternalWorkout", "saveExternalWorkoutReqDto", saveExternalWorkoutReqDto)
      const localVarPath = `/person/{person}/service/save-external-workout`.replace(
        `{${"person"}}`,
        encodeURIComponent(String(person))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        saveExternalWorkoutReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * ExternalWorkoutApi - functional programming interface
 * @export
 */
export const ExternalWorkoutApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = ExternalWorkoutApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} person
     * @param {SaveExternalWorkoutReqDto} saveExternalWorkoutReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveExternalWorkout(
      person: string,
      saveExternalWorkoutReqDto: SaveExternalWorkoutReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySessionDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveExternalWorkout(
        person,
        saveExternalWorkoutReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * ExternalWorkoutApi - factory interface
 * @export
 */
export const ExternalWorkoutApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = ExternalWorkoutApiFp(configuration)
  return {
    /**
     *
     * @param {string} person
     * @param {SaveExternalWorkoutReqDto} saveExternalWorkoutReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveExternalWorkout(
      person: string,
      saveExternalWorkoutReqDto: SaveExternalWorkoutReqDto,
      options?: any
    ): AxiosPromise<ActivitySessionDto> {
      return localVarFp
        .saveExternalWorkout(person, saveExternalWorkoutReqDto, options)
        .then((request) => request(axios, basePath))
    },
  }
}

/**
 * ExternalWorkoutApi - object-oriented interface
 * @export
 * @class ExternalWorkoutApi
 * @extends {BaseAPI}
 */
export class ExternalWorkoutApi extends BaseAPI {
  /**
   *
   * @param {string} person
   * @param {SaveExternalWorkoutReqDto} saveExternalWorkoutReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof ExternalWorkoutApi
   */
  public saveExternalWorkout(
    person: string,
    saveExternalWorkoutReqDto: SaveExternalWorkoutReqDto,
    options?: AxiosRequestConfig
  ) {
    return ExternalWorkoutApiFp(this.configuration)
      .saveExternalWorkout(person, saveExternalWorkoutReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * FileControllerApi - axios parameter creator
 * @export
 */
export const FileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bytes: async (uuid: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'uuid' is not null or undefined
      assertParamExists("bytes", "uuid", uuid)
      const localVarPath = `/file/bytes/{uuid}`.replace(`{${"uuid"}}`, encodeURIComponent(String(uuid)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * FileControllerApi - functional programming interface
 * @export
 */
export const FileControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = FileControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async bytes(
      uuid: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<object>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.bytes(uuid, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * FileControllerApi - factory interface
 * @export
 */
export const FileControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = FileControllerApiFp(configuration)
  return {
    /**
     *
     * @param {string} uuid
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    bytes(uuid: string, options?: any): AxiosPromise<object> {
      return localVarFp.bytes(uuid, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * FileControllerApi - object-oriented interface
 * @export
 * @class FileControllerApi
 * @extends {BaseAPI}
 */
export class FileControllerApi extends BaseAPI {
  /**
   *
   * @param {string} uuid
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof FileControllerApi
   */
  public bytes(uuid: string, options?: AxiosRequestConfig) {
    return FileControllerApiFp(this.configuration)
      .bytes(uuid, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * HealthProfileControllerApi - axios parameter creator
 * @export
 */
export const HealthProfileControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealthProfile: async (person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("getHealthProfile", "person", person)
      const localVarPath = `/health-profile/{person}`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * HealthProfileControllerApi - functional programming interface
 * @export
 */
export const HealthProfileControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = HealthProfileControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getHealthProfile(
      person: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HealthProfileDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getHealthProfile(person, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * HealthProfileControllerApi - factory interface
 * @export
 */
export const HealthProfileControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = HealthProfileControllerApiFp(configuration)
  return {
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getHealthProfile(person: string, options?: any): AxiosPromise<HealthProfileDto> {
      return localVarFp.getHealthProfile(person, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * HealthProfileControllerApi - object-oriented interface
 * @export
 * @class HealthProfileControllerApi
 * @extends {BaseAPI}
 */
export class HealthProfileControllerApi extends BaseAPI {
  /**
   *
   * @param {string} person
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof HealthProfileControllerApi
   */
  public getHealthProfile(person: string, options?: AxiosRequestConfig) {
    return HealthProfileControllerApiFp(this.configuration)
      .getHealthProfile(person, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * PeriodApi - axios parameter creator
 * @export
 */
export const PeriodApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelPeriod: async (person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("cancelPeriod", "person", person)
      const localVarPath = `/period/{person}/cancel`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivePeriod: async (person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("getActivePeriod", "person", person)
      const localVarPath = `/period/{person}`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {string} period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPeriod: async (person: string, period: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("getPeriod", "person", person)
      // verify required parameter 'period' is not null or undefined
      assertParamExists("getPeriod", "period", period)
      const localVarPath = `/period/{person}/{period}`
        .replace(`{${"person"}}`, encodeURIComponent(String(person)))
        .replace(`{${"period"}}`, encodeURIComponent(String(period)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {StartPeriodRequestDto} startPeriodRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startPeriod: async (
      person: string,
      startPeriodRequestDto: StartPeriodRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("startPeriod", "person", person)
      // verify required parameter 'startPeriodRequestDto' is not null or undefined
      assertParamExists("startPeriod", "startPeriodRequestDto", startPeriodRequestDto)
      const localVarPath = `/period/{person}/start`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(startPeriodRequestDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PeriodApi - functional programming interface
 * @export
 */
export const PeriodApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PeriodApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cancelPeriod(
      person: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cancelPeriod(person, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getActivePeriod(
      person: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimPeriodDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getActivePeriod(person, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {string} period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPeriod(
      person: string,
      period: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimPeriodDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPeriod(person, period, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {StartPeriodRequestDto} startPeriodRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startPeriod(
      person: string,
      startPeriodRequestDto: StartPeriodRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<VimPeriodDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startPeriod(person, startPeriodRequestDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PeriodApi - factory interface
 * @export
 */
export const PeriodApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PeriodApiFp(configuration)
  return {
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cancelPeriod(person: string, options?: any): AxiosPromise<void> {
      return localVarFp.cancelPeriod(person, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getActivePeriod(person: string, options?: any): AxiosPromise<VimPeriodDto> {
      return localVarFp.getActivePeriod(person, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {string} period
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPeriod(person: string, period: string, options?: any): AxiosPromise<VimPeriodDto> {
      return localVarFp.getPeriod(person, period, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {StartPeriodRequestDto} startPeriodRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startPeriod(
      person: string,
      startPeriodRequestDto: StartPeriodRequestDto,
      options?: any
    ): AxiosPromise<VimPeriodDto> {
      return localVarFp.startPeriod(person, startPeriodRequestDto, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * PeriodApi - object-oriented interface
 * @export
 * @class PeriodApi
 * @extends {BaseAPI}
 */
export class PeriodApi extends BaseAPI {
  /**
   *
   * @param {string} person
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PeriodApi
   */
  public cancelPeriod(person: string, options?: AxiosRequestConfig) {
    return PeriodApiFp(this.configuration)
      .cancelPeriod(person, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PeriodApi
   */
  public getActivePeriod(person: string, options?: AxiosRequestConfig) {
    return PeriodApiFp(this.configuration)
      .getActivePeriod(person, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {string} period
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PeriodApi
   */
  public getPeriod(person: string, period: string, options?: AxiosRequestConfig) {
    return PeriodApiFp(this.configuration)
      .getPeriod(person, period, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {StartPeriodRequestDto} startPeriodRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PeriodApi
   */
  public startPeriod(person: string, startPeriodRequestDto: StartPeriodRequestDto, options?: AxiosRequestConfig) {
    return PeriodApiFp(this.configuration)
      .startPeriod(person, startPeriodRequestDto, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * PersonApi - axios parameter creator
 * @export
 */
export const PersonApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} person
     * @param {number} page
     * @param {number} size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assessments: async (
      person: string,
      page: number,
      size: number,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("assessments", "person", person)
      // verify required parameter 'page' is not null or undefined
      assertParamExists("assessments", "page", page)
      // verify required parameter 'size' is not null or undefined
      assertParamExists("assessments", "size", size)
      const localVarPath = `/person/{person}/assessment`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (page !== undefined) {
        localVarQueryParameter["page"] = page
      }

      if (size !== undefined) {
        localVarQueryParameter["size"] = size
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {ContinueEmailAuthenticationReqDto} continueEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    continueEmailAuthentication: async (
      continueEmailAuthenticationReqDto: ContinueEmailAuthenticationReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'continueEmailAuthenticationReqDto' is not null or undefined
      assertParamExists(
        "continueEmailAuthentication",
        "continueEmailAuthenticationReqDto",
        continueEmailAuthenticationReqDto
      )
      const localVarPath = `/person/continue-email-authentication`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        continueEmailAuthenticationReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {ContinueEmailAuthenticationReqDto} continueEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    continueEmailAuthenticationWithPerson: async (
      person: string,
      continueEmailAuthenticationReqDto: ContinueEmailAuthenticationReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("continueEmailAuthenticationWithPerson", "person", person)
      // verify required parameter 'continueEmailAuthenticationReqDto' is not null or undefined
      assertParamExists(
        "continueEmailAuthenticationWithPerson",
        "continueEmailAuthenticationReqDto",
        continueEmailAuthenticationReqDto
      )
      const localVarPath = `/person/{person}/continue-email-authentication`.replace(
        `{${"person"}}`,
        encodeURIComponent(String(person))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        continueEmailAuthenticationReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPerson: async (person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("getPerson", "person", person)
      const localVarPath = `/person/{person}`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getPersonOld: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/person`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prescription: async (person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("prescription", "person", person)
      const localVarPath = `/person/{person}/prescription`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {AssessmentDto} assessmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAssessment: async (
      person: string,
      assessmentDto: AssessmentDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("saveAssessment", "person", person)
      // verify required parameter 'assessmentDto' is not null or undefined
      assertParamExists("saveAssessment", "assessmentDto", assessmentDto)
      const localVarPath = `/person/{person}/assessment`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(assessmentDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {StartEmailAuthenticationReqDto} startEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startEmailAuthentication: async (
      startEmailAuthenticationReqDto: StartEmailAuthenticationReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'startEmailAuthenticationReqDto' is not null or undefined
      assertParamExists("startEmailAuthentication", "startEmailAuthenticationReqDto", startEmailAuthenticationReqDto)
      const localVarPath = `/person/start-email-authentication`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        startEmailAuthenticationReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {StartEmailAuthenticationReqDto} startEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startEmailAuthenticationWithPerson: async (
      person: string,
      startEmailAuthenticationReqDto: StartEmailAuthenticationReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("startEmailAuthenticationWithPerson", "person", person)
      // verify required parameter 'startEmailAuthenticationReqDto' is not null or undefined
      assertParamExists(
        "startEmailAuthenticationWithPerson",
        "startEmailAuthenticationReqDto",
        startEmailAuthenticationReqDto
      )
      const localVarPath = `/person/{person}/start-email-authentication`.replace(
        `{${"person"}}`,
        encodeURIComponent(String(person))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        startEmailAuthenticationReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {PersonDto} personDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePerson: async (
      person: string,
      personDto: PersonDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("updatePerson", "person", person)
      // verify required parameter 'personDto' is not null or undefined
      assertParamExists("updatePerson", "personDto", personDto)
      const localVarPath = `/person/{person}`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(personDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {PersonDto} personDto
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    updatePersonOld: async (personDto: PersonDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'personDto' is not null or undefined
      assertParamExists("updatePersonOld", "personDto", personDto)
      const localVarPath = `/person`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(personDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PersonApi - functional programming interface
 * @export
 */
export const PersonApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PersonApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} person
     * @param {number} page
     * @param {number} size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assessments(
      person: string,
      page: number,
      size: number,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AssessmentDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assessments(person, page, size, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {ContinueEmailAuthenticationReqDto} continueEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async continueEmailAuthentication(
      continueEmailAuthenticationReqDto: ContinueEmailAuthenticationReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContinueEmailAuthenticationResDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.continueEmailAuthentication(
        continueEmailAuthenticationReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {ContinueEmailAuthenticationReqDto} continueEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async continueEmailAuthenticationWithPerson(
      person: string,
      continueEmailAuthenticationReqDto: ContinueEmailAuthenticationReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ContinueEmailAuthenticationResDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.continueEmailAuthenticationWithPerson(
        person,
        continueEmailAuthenticationReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPerson(
      person: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPerson(person, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async getPersonOld(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPersonOld(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async prescription(
      person: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PrescriptionDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.prescription(person, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {AssessmentDto} assessmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveAssessment(
      person: string,
      assessmentDto: AssessmentDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveAssessment(person, assessmentDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {StartEmailAuthenticationReqDto} startEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startEmailAuthentication(
      startEmailAuthenticationReqDto: StartEmailAuthenticationReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartEmailAuthenticationResDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startEmailAuthentication(
        startEmailAuthenticationReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {StartEmailAuthenticationReqDto} startEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startEmailAuthenticationWithPerson(
      person: string,
      startEmailAuthenticationReqDto: StartEmailAuthenticationReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartEmailAuthenticationResDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startEmailAuthenticationWithPerson(
        person,
        startEmailAuthenticationReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {PersonDto} personDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePerson(
      person: string,
      personDto: PersonDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePerson(person, personDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {PersonDto} personDto
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    async updatePersonOld(
      personDto: PersonDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PersonDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePersonOld(personDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PersonApi - factory interface
 * @export
 */
export const PersonApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = PersonApiFp(configuration)
  return {
    /**
     *
     * @param {string} person
     * @param {number} page
     * @param {number} size
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assessments(person: string, page: number, size: number, options?: any): AxiosPromise<Array<AssessmentDto>> {
      return localVarFp.assessments(person, page, size, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {ContinueEmailAuthenticationReqDto} continueEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    continueEmailAuthentication(
      continueEmailAuthenticationReqDto: ContinueEmailAuthenticationReqDto,
      options?: any
    ): AxiosPromise<ContinueEmailAuthenticationResDto> {
      return localVarFp
        .continueEmailAuthentication(continueEmailAuthenticationReqDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {ContinueEmailAuthenticationReqDto} continueEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    continueEmailAuthenticationWithPerson(
      person: string,
      continueEmailAuthenticationReqDto: ContinueEmailAuthenticationReqDto,
      options?: any
    ): AxiosPromise<ContinueEmailAuthenticationResDto> {
      return localVarFp
        .continueEmailAuthenticationWithPerson(person, continueEmailAuthenticationReqDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPerson(person: string, options?: any): AxiosPromise<PersonDto> {
      return localVarFp.getPerson(person, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    getPersonOld(options?: any): AxiosPromise<PersonDto> {
      return localVarFp.getPersonOld(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    prescription(person: string, options?: any): AxiosPromise<PrescriptionDto> {
      return localVarFp.prescription(person, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {AssessmentDto} assessmentDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveAssessment(person: string, assessmentDto: AssessmentDto, options?: any): AxiosPromise<void> {
      return localVarFp.saveAssessment(person, assessmentDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {StartEmailAuthenticationReqDto} startEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startEmailAuthentication(
      startEmailAuthenticationReqDto: StartEmailAuthenticationReqDto,
      options?: any
    ): AxiosPromise<StartEmailAuthenticationResDto> {
      return localVarFp
        .startEmailAuthentication(startEmailAuthenticationReqDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {StartEmailAuthenticationReqDto} startEmailAuthenticationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startEmailAuthenticationWithPerson(
      person: string,
      startEmailAuthenticationReqDto: StartEmailAuthenticationReqDto,
      options?: any
    ): AxiosPromise<StartEmailAuthenticationResDto> {
      return localVarFp
        .startEmailAuthenticationWithPerson(person, startEmailAuthenticationReqDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {PersonDto} personDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePerson(person: string, personDto: PersonDto, options?: any): AxiosPromise<PersonDto> {
      return localVarFp.updatePerson(person, personDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {PersonDto} personDto
     * @param {*} [options] Override http request option.
     * @deprecated
     * @throws {RequiredError}
     */
    updatePersonOld(personDto: PersonDto, options?: any): AxiosPromise<PersonDto> {
      return localVarFp.updatePersonOld(personDto, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * PersonApi - object-oriented interface
 * @export
 * @class PersonApi
 * @extends {BaseAPI}
 */
export class PersonApi extends BaseAPI {
  /**
   *
   * @param {string} person
   * @param {number} page
   * @param {number} size
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public assessments(person: string, page: number, size: number, options?: AxiosRequestConfig) {
    return PersonApiFp(this.configuration)
      .assessments(person, page, size, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {ContinueEmailAuthenticationReqDto} continueEmailAuthenticationReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public continueEmailAuthentication(
    continueEmailAuthenticationReqDto: ContinueEmailAuthenticationReqDto,
    options?: AxiosRequestConfig
  ) {
    return PersonApiFp(this.configuration)
      .continueEmailAuthentication(continueEmailAuthenticationReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {ContinueEmailAuthenticationReqDto} continueEmailAuthenticationReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public continueEmailAuthenticationWithPerson(
    person: string,
    continueEmailAuthenticationReqDto: ContinueEmailAuthenticationReqDto,
    options?: AxiosRequestConfig
  ) {
    return PersonApiFp(this.configuration)
      .continueEmailAuthenticationWithPerson(person, continueEmailAuthenticationReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public getPerson(person: string, options?: AxiosRequestConfig) {
    return PersonApiFp(this.configuration)
      .getPerson(person, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public getPersonOld(options?: AxiosRequestConfig) {
    return PersonApiFp(this.configuration)
      .getPersonOld(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public prescription(person: string, options?: AxiosRequestConfig) {
    return PersonApiFp(this.configuration)
      .prescription(person, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {AssessmentDto} assessmentDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public saveAssessment(person: string, assessmentDto: AssessmentDto, options?: AxiosRequestConfig) {
    return PersonApiFp(this.configuration)
      .saveAssessment(person, assessmentDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {StartEmailAuthenticationReqDto} startEmailAuthenticationReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public startEmailAuthentication(
    startEmailAuthenticationReqDto: StartEmailAuthenticationReqDto,
    options?: AxiosRequestConfig
  ) {
    return PersonApiFp(this.configuration)
      .startEmailAuthentication(startEmailAuthenticationReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {StartEmailAuthenticationReqDto} startEmailAuthenticationReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public startEmailAuthenticationWithPerson(
    person: string,
    startEmailAuthenticationReqDto: StartEmailAuthenticationReqDto,
    options?: AxiosRequestConfig
  ) {
    return PersonApiFp(this.configuration)
      .startEmailAuthenticationWithPerson(person, startEmailAuthenticationReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {PersonDto} personDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public updatePerson(person: string, personDto: PersonDto, options?: AxiosRequestConfig) {
    return PersonApiFp(this.configuration)
      .updatePerson(person, personDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {PersonDto} personDto
   * @param {*} [options] Override http request option.
   * @deprecated
   * @throws {RequiredError}
   * @memberof PersonApi
   */
  public updatePersonOld(personDto: PersonDto, options?: AxiosRequestConfig) {
    return PersonApiFp(this.configuration)
      .updatePersonOld(personDto, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * PingControllerApi - axios parameter creator
 * @export
 */
export const PingControllerApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ping: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/ping`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemStatus: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/system-status`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * PingControllerApi - functional programming interface
 * @export
 */
export const PingControllerApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PingControllerApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async ping(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<string>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.ping(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async systemStatus(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SystemStatusResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.systemStatus(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * PingControllerApi - factory interface
 * @export
 */
export const PingControllerApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = PingControllerApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    ping(options?: any): AxiosPromise<string> {
      return localVarFp.ping(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    systemStatus(options?: any): AxiosPromise<SystemStatusResponse> {
      return localVarFp.systemStatus(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * PingControllerApi - object-oriented interface
 * @export
 * @class PingControllerApi
 * @extends {BaseAPI}
 */
export class PingControllerApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PingControllerApi
   */
  public ping(options?: AxiosRequestConfig) {
    return PingControllerApiFp(this.configuration)
      .ping(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PingControllerApi
   */
  public systemStatus(options?: AxiosRequestConfig) {
    return PingControllerApiFp(this.configuration)
      .systemStatus(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SignUpApi - axios parameter creator
 * @export
 */
export const SignUpApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {SignUpRequestDto} signUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signUp: async (signUpRequestDto: SignUpRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'signUpRequestDto' is not null or undefined
      assertParamExists("signUp", "signUpRequestDto", signUpRequestDto)
      const localVarPath = `/service/sign-up`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(signUpRequestDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SignUpApi - functional programming interface
 * @export
 */
export const SignUpApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SignUpApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {SignUpRequestDto} signUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async signUp(
      signUpRequestDto: SignUpRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SignUpResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.signUp(signUpRequestDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SignUpApi - factory interface
 * @export
 */
export const SignUpApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SignUpApiFp(configuration)
  return {
    /**
     *
     * @param {SignUpRequestDto} signUpRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    signUp(signUpRequestDto: SignUpRequestDto, options?: any): AxiosPromise<SignUpResponseDto> {
      return localVarFp.signUp(signUpRequestDto, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * SignUpApi - object-oriented interface
 * @export
 * @class SignUpApi
 * @extends {BaseAPI}
 */
export class SignUpApi extends BaseAPI {
  /**
   *
   * @param {SignUpRequestDto} signUpRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SignUpApi
   */
  public signUp(signUpRequestDto: SignUpRequestDto, options?: AxiosRequestConfig) {
    return SignUpApiFp(this.configuration)
      .signUp(signUpRequestDto, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SimulatorApi - axios parameter creator
 * @export
 */
export const SimulatorApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CreateDump4tuneReportRequestDto} createDump4tuneReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDump4tuneReport: async (
      createDump4tuneReportRequestDto: CreateDump4tuneReportRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createDump4tuneReportRequestDto' is not null or undefined
      assertParamExists("createDump4tuneReport", "createDump4tuneReportRequestDto", createDump4tuneReportRequestDto)
      const localVarPath = `/web/create-dump-4tune-report`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createDump4tuneReportRequestDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateExercisesReportRequestDto} createExercisesReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExercisesReport: async (
      createExercisesReportRequestDto: CreateExercisesReportRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createExercisesReportRequestDto' is not null or undefined
      assertParamExists("createExercisesReport", "createExercisesReportRequestDto", createExercisesReportRequestDto)
      const localVarPath = `/web/create-exercises-report`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createExercisesReportRequestDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreatePickExercisesReportRequestDto} createPickExercisesReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPickExercisesReport: async (
      createPickExercisesReportRequestDto: CreatePickExercisesReportRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createPickExercisesReportRequestDto' is not null or undefined
      assertParamExists(
        "createPickExercisesReport",
        "createPickExercisesReportRequestDto",
        createPickExercisesReportRequestDto
      )
      const localVarPath = `/web/create-pick-exercises-report`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createPickExercisesReportRequestDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateProgramsReportRequestDto} createProgramsReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProgramsReport: async (
      createProgramsReportRequestDto: CreateProgramsReportRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createProgramsReportRequestDto' is not null or undefined
      assertParamExists("createProgramsReport", "createProgramsReportRequestDto", createProgramsReportRequestDto)
      const localVarPath = `/web/create-programs-report`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createProgramsReportRequestDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateWorkoutRequestDto} createWorkoutRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simCreateWorkout: async (
      createWorkoutRequestDto: CreateWorkoutRequestDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createWorkoutRequestDto' is not null or undefined
      assertParamExists("simCreateWorkout", "createWorkoutRequestDto", createWorkoutRequestDto)
      const localVarPath = `/web/simulator/get-workout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createWorkoutRequestDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SimulatorApi - functional programming interface
 * @export
 */
export const SimulatorApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SimulatorApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CreateDump4tuneReportRequestDto} createDump4tuneReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createDump4tuneReport(
      createDump4tuneReportRequestDto: CreateDump4tuneReportRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createDump4tuneReport(
        createDump4tuneReportRequestDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateExercisesReportRequestDto} createExercisesReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createExercisesReport(
      createExercisesReportRequestDto: CreateExercisesReportRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createExercisesReport(
        createExercisesReportRequestDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreatePickExercisesReportRequestDto} createPickExercisesReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createPickExercisesReport(
      createPickExercisesReportRequestDto: CreatePickExercisesReportRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createPickExercisesReport(
        createPickExercisesReportRequestDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateProgramsReportRequestDto} createProgramsReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createProgramsReport(
      createProgramsReportRequestDto: CreateProgramsReportRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createProgramsReport(
        createProgramsReportRequestDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateWorkoutRequestDto} createWorkoutRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async simCreateWorkout(
      createWorkoutRequestDto: CreateWorkoutRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DailyWorkout>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.simCreateWorkout(createWorkoutRequestDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SimulatorApi - factory interface
 * @export
 */
export const SimulatorApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SimulatorApiFp(configuration)
  return {
    /**
     *
     * @param {CreateDump4tuneReportRequestDto} createDump4tuneReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createDump4tuneReport(
      createDump4tuneReportRequestDto: CreateDump4tuneReportRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .createDump4tuneReport(createDump4tuneReportRequestDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateExercisesReportRequestDto} createExercisesReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createExercisesReport(
      createExercisesReportRequestDto: CreateExercisesReportRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .createExercisesReport(createExercisesReportRequestDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreatePickExercisesReportRequestDto} createPickExercisesReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createPickExercisesReport(
      createPickExercisesReportRequestDto: CreatePickExercisesReportRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .createPickExercisesReport(createPickExercisesReportRequestDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateProgramsReportRequestDto} createProgramsReportRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createProgramsReport(
      createProgramsReportRequestDto: CreateProgramsReportRequestDto,
      options?: any
    ): AxiosPromise<void> {
      return localVarFp
        .createProgramsReport(createProgramsReportRequestDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateWorkoutRequestDto} createWorkoutRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    simCreateWorkout(createWorkoutRequestDto: CreateWorkoutRequestDto, options?: any): AxiosPromise<DailyWorkout> {
      return localVarFp.simCreateWorkout(createWorkoutRequestDto, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * SimulatorApi - object-oriented interface
 * @export
 * @class SimulatorApi
 * @extends {BaseAPI}
 */
export class SimulatorApi extends BaseAPI {
  /**
   *
   * @param {CreateDump4tuneReportRequestDto} createDump4tuneReportRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SimulatorApi
   */
  public createDump4tuneReport(
    createDump4tuneReportRequestDto: CreateDump4tuneReportRequestDto,
    options?: AxiosRequestConfig
  ) {
    return SimulatorApiFp(this.configuration)
      .createDump4tuneReport(createDump4tuneReportRequestDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateExercisesReportRequestDto} createExercisesReportRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SimulatorApi
   */
  public createExercisesReport(
    createExercisesReportRequestDto: CreateExercisesReportRequestDto,
    options?: AxiosRequestConfig
  ) {
    return SimulatorApiFp(this.configuration)
      .createExercisesReport(createExercisesReportRequestDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreatePickExercisesReportRequestDto} createPickExercisesReportRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SimulatorApi
   */
  public createPickExercisesReport(
    createPickExercisesReportRequestDto: CreatePickExercisesReportRequestDto,
    options?: AxiosRequestConfig
  ) {
    return SimulatorApiFp(this.configuration)
      .createPickExercisesReport(createPickExercisesReportRequestDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateProgramsReportRequestDto} createProgramsReportRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SimulatorApi
   */
  public createProgramsReport(
    createProgramsReportRequestDto: CreateProgramsReportRequestDto,
    options?: AxiosRequestConfig
  ) {
    return SimulatorApiFp(this.configuration)
      .createProgramsReport(createProgramsReportRequestDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateWorkoutRequestDto} createWorkoutRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SimulatorApi
   */
  public simCreateWorkout(createWorkoutRequestDto: CreateWorkoutRequestDto, options?: AxiosRequestConfig) {
    return SimulatorApiFp(this.configuration)
      .simCreateWorkout(createWorkoutRequestDto, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SpondApi - axios parameter creator
 * @export
 */
export const SpondApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spondImportEmailMapping: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("spondImportEmailMapping", "file", file)
      const localVarPath = `/web/spond/email-mapping`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spondImportMembers: async (file: any, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'file' is not null or undefined
      assertParamExists("spondImportMembers", "file", file)
      const localVarPath = `/web/spond/upload-members`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any
      const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)()

      if (file !== undefined) {
        localVarFormParams.append("file", file as any)
      }

      localVarHeaderParameter["Content-Type"] = "multipart/form-data"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = localVarFormParams

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SpondApi - functional programming interface
 * @export
 */
export const SpondApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SpondApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spondImportEmailMapping(
      file: any,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spondImportEmailMapping(file, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async spondImportMembers(
      file: any,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<JobResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.spondImportMembers(file, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SpondApi - factory interface
 * @export
 */
export const SpondApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = SpondApiFp(configuration)
  return {
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spondImportEmailMapping(file: any, options?: any): AxiosPromise<JobResponseDto> {
      return localVarFp.spondImportEmailMapping(file, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {any} file
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    spondImportMembers(file: any, options?: any): AxiosPromise<JobResponseDto> {
      return localVarFp.spondImportMembers(file, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * SpondApi - object-oriented interface
 * @export
 * @class SpondApi
 * @extends {BaseAPI}
 */
export class SpondApi extends BaseAPI {
  /**
   *
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpondApi
   */
  public spondImportEmailMapping(file: any, options?: AxiosRequestConfig) {
    return SpondApiFp(this.configuration)
      .spondImportEmailMapping(file, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {any} file
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SpondApi
   */
  public spondImportMembers(file: any, options?: AxiosRequestConfig) {
    return SpondApiFp(this.configuration)
      .spondImportMembers(file, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * SubscriptionApi - axios parameter creator
 * @export
 */
export const SubscriptionApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currentSubscription: async (person: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("currentSubscription", "person", person)
      const localVarPath = `/subscription/{person}`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {StartVippsSubscriptionReqDto} startVippsSubscriptionReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startVippsSubscription: async (
      person: string,
      startVippsSubscriptionReqDto: StartVippsSubscriptionReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("startVippsSubscription", "person", person)
      // verify required parameter 'startVippsSubscriptionReqDto' is not null or undefined
      assertParamExists("startVippsSubscription", "startVippsSubscriptionReqDto", startVippsSubscriptionReqDto)
      const localVarPath = `/subscription/{person}/start-vipps-subscription`.replace(
        `{${"person"}}`,
        encodeURIComponent(String(person))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        startVippsSubscriptionReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {ValidateVippsSubscriptionReqDto} validateVippsSubscriptionReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateVippsSubscription: async (
      person: string,
      validateVippsSubscriptionReqDto: ValidateVippsSubscriptionReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("validateVippsSubscription", "person", person)
      // verify required parameter 'validateVippsSubscriptionReqDto' is not null or undefined
      assertParamExists("validateVippsSubscription", "validateVippsSubscriptionReqDto", validateVippsSubscriptionReqDto)
      const localVarPath = `/subscription/{person}/validate-vipps-subscription`.replace(
        `{${"person"}}`,
        encodeURIComponent(String(person))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        validateVippsSubscriptionReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vippsCallback: async (subscription: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'subscription' is not null or undefined
      assertParamExists("vippsCallback", "subscription", subscription)
      const localVarPath = `/vipps/callback/{subscription}`.replace(
        `{${"subscription"}}`,
        encodeURIComponent(String(subscription))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * SubscriptionApi - functional programming interface
 * @export
 */
export const SubscriptionApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = SubscriptionApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async currentSubscription(
      person: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<SubscriptionDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.currentSubscription(person, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {StartVippsSubscriptionReqDto} startVippsSubscriptionReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async startVippsSubscription(
      person: string,
      startVippsSubscriptionReqDto: StartVippsSubscriptionReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StartVippsSubscriptionResDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.startVippsSubscription(
        person,
        startVippsSubscriptionReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {ValidateVippsSubscriptionReqDto} validateVippsSubscriptionReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async validateVippsSubscription(
      person: string,
      validateVippsSubscriptionReqDto: ValidateVippsSubscriptionReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ValidateVippsSubscriptionResDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.validateVippsSubscription(
        person,
        validateVippsSubscriptionReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async vippsCallback(
      subscription: string,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.vippsCallback(subscription, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * SubscriptionApi - factory interface
 * @export
 */
export const SubscriptionApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance
) {
  const localVarFp = SubscriptionApiFp(configuration)
  return {
    /**
     *
     * @param {string} person
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    currentSubscription(person: string, options?: any): AxiosPromise<SubscriptionDto> {
      return localVarFp.currentSubscription(person, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {StartVippsSubscriptionReqDto} startVippsSubscriptionReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    startVippsSubscription(
      person: string,
      startVippsSubscriptionReqDto: StartVippsSubscriptionReqDto,
      options?: any
    ): AxiosPromise<StartVippsSubscriptionResDto> {
      return localVarFp
        .startVippsSubscription(person, startVippsSubscriptionReqDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {ValidateVippsSubscriptionReqDto} validateVippsSubscriptionReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    validateVippsSubscription(
      person: string,
      validateVippsSubscriptionReqDto: ValidateVippsSubscriptionReqDto,
      options?: any
    ): AxiosPromise<ValidateVippsSubscriptionResDto> {
      return localVarFp
        .validateVippsSubscription(person, validateVippsSubscriptionReqDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} subscription
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    vippsCallback(subscription: string, options?: any): AxiosPromise<void> {
      return localVarFp.vippsCallback(subscription, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * SubscriptionApi - object-oriented interface
 * @export
 * @class SubscriptionApi
 * @extends {BaseAPI}
 */
export class SubscriptionApi extends BaseAPI {
  /**
   *
   * @param {string} person
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public currentSubscription(person: string, options?: AxiosRequestConfig) {
    return SubscriptionApiFp(this.configuration)
      .currentSubscription(person, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {StartVippsSubscriptionReqDto} startVippsSubscriptionReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public startVippsSubscription(
    person: string,
    startVippsSubscriptionReqDto: StartVippsSubscriptionReqDto,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionApiFp(this.configuration)
      .startVippsSubscription(person, startVippsSubscriptionReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {ValidateVippsSubscriptionReqDto} validateVippsSubscriptionReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public validateVippsSubscription(
    person: string,
    validateVippsSubscriptionReqDto: ValidateVippsSubscriptionReqDto,
    options?: AxiosRequestConfig
  ) {
    return SubscriptionApiFp(this.configuration)
      .validateVippsSubscription(person, validateVippsSubscriptionReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} subscription
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof SubscriptionApi
   */
  public vippsCallback(subscription: string, options?: AxiosRequestConfig) {
    return SubscriptionApiFp(this.configuration)
      .vippsCallback(subscription, options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UrlsApi - axios parameter creator
 * @export
 */
export const UrlsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assessmentGuide: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/url/assessment-guide.pdf`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UrlsApi - functional programming interface
 * @export
 */
export const UrlsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UrlsApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async assessmentGuide(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.assessmentGuide(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * UrlsApi - factory interface
 * @export
 */
export const UrlsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UrlsApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    assessmentGuide(options?: any): AxiosPromise<any> {
      return localVarFp.assessmentGuide(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * UrlsApi - object-oriented interface
 * @export
 * @class UrlsApi
 * @extends {BaseAPI}
 */
export class UrlsApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UrlsApi
   */
  public assessmentGuide(options?: AxiosRequestConfig) {
    return UrlsApiFp(this.configuration)
      .assessmentGuide(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {CheckRequestDto} checkRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    versionCheck: async (checkRequestDto: CheckRequestDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'checkRequestDto' is not null or undefined
      assertParamExists("versionCheck", "checkRequestDto", checkRequestDto)
      const localVarPath = `/version-check`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(checkRequestDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    whoAmI: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/who-am-i`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {CheckRequestDto} checkRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async versionCheck(
      checkRequestDto: CheckRequestDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CheckResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.versionCheck(checkRequestDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async whoAmI(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WhoAmIResponseDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.whoAmI(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = UserApiFp(configuration)
  return {
    /**
     *
     * @param {CheckRequestDto} checkRequestDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    versionCheck(checkRequestDto: CheckRequestDto, options?: any): AxiosPromise<CheckResponseDto> {
      return localVarFp.versionCheck(checkRequestDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    whoAmI(options?: any): AxiosPromise<WhoAmIResponseDto> {
      return localVarFp.whoAmI(options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
  /**
   *
   * @param {CheckRequestDto} checkRequestDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public versionCheck(checkRequestDto: CheckRequestDto, options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .versionCheck(checkRequestDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof UserApi
   */
  public whoAmI(options?: AxiosRequestConfig) {
    return UserApiFp(this.configuration)
      .whoAmI(options)
      .then((request) => request(this.axios, this.basePath))
  }
}

/**
 * WorkoutApi - axios parameter creator
 * @export
 */
export const WorkoutApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    animations: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/workout/animations`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {CreateWorkoutReqDto} createWorkoutReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWorkout: async (
      createWorkoutReqDto: CreateWorkoutReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'createWorkoutReqDto' is not null or undefined
      assertParamExists("createWorkout", "createWorkoutReqDto", createWorkoutReqDto)
      const localVarPath = `/create-workout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(createWorkoutReqDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {CreateWorkoutSpecificationReqDto} createWorkoutSpecificationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWorkoutSpecification: async (
      person: string,
      createWorkoutSpecificationReqDto: CreateWorkoutSpecificationReqDto,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("createWorkoutSpecification", "person", person)
      // verify required parameter 'createWorkoutSpecificationReqDto' is not null or undefined
      assertParamExists(
        "createWorkoutSpecification",
        "createWorkoutSpecificationReqDto",
        createWorkoutSpecificationReqDto
      )
      const localVarPath = `/{person}/create-workout-specification`.replace(
        `{${"person"}}`,
        encodeURIComponent(String(person))
      )
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(
        createWorkoutSpecificationReqDto,
        localVarRequestOptions,
        configuration
      )

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} workout
     * @param {boolean} [animations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkout: async (
      workout: string,
      animations?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'workout' is not null or undefined
      assertParamExists("getWorkout", "workout", workout)
      const localVarPath = `/workout/{workout}`.replace(`{${"workout"}}`, encodeURIComponent(String(workout)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (animations !== undefined) {
        localVarQueryParameter["animations"] = animations
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {string} person
     * @param {string} from
     * @param {string} to
     * @param {boolean} [animations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkouts: async (
      person: string,
      from: string,
      to: string,
      animations?: boolean,
      options: AxiosRequestConfig = {}
    ): Promise<RequestArgs> => {
      // verify required parameter 'person' is not null or undefined
      assertParamExists("getWorkouts", "person", person)
      // verify required parameter 'from' is not null or undefined
      assertParamExists("getWorkouts", "from", from)
      // verify required parameter 'to' is not null or undefined
      assertParamExists("getWorkouts", "to", to)
      const localVarPath = `/person/{person}/workout`.replace(`{${"person"}}`, encodeURIComponent(String(person)))
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      if (from !== undefined) {
        localVarQueryParameter["from"] =
          (from as any) instanceof Date ? (from as any).toISOString().substr(0, 10) : from
      }

      if (to !== undefined) {
        localVarQueryParameter["to"] = (to as any) instanceof Date ? (to as any).toISOString().substr(0, 10) : to
      }

      if (animations !== undefined) {
        localVarQueryParameter["animations"] = animations
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
    /**
     *
     * @param {WorkoutResultDto} workoutResultDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveWorkout: async (workoutResultDto: WorkoutResultDto, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      // verify required parameter 'workoutResultDto' is not null or undefined
      assertParamExists("saveWorkout", "workoutResultDto", workoutResultDto)
      const localVarPath = `/save-workout`
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL)
      let baseOptions
      if (configuration) {
        baseOptions = configuration.baseOptions
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options }
      const localVarHeaderParameter = {} as any
      const localVarQueryParameter = {} as any

      localVarHeaderParameter["Content-Type"] = "application/json"

      setSearchParams(localVarUrlObj, localVarQueryParameter)
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {}
      localVarRequestOptions.headers = { ...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers }
      localVarRequestOptions.data = serializeDataIfNeeded(workoutResultDto, localVarRequestOptions, configuration)

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      }
    },
  }
}

/**
 * WorkoutApi - functional programming interface
 * @export
 */
export const WorkoutApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = WorkoutApiAxiosParamCreator(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async animations(
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<AnimationDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.animations(options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {CreateWorkoutReqDto} createWorkoutReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createWorkout(
      createWorkoutReqDto: CreateWorkoutReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkoutDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkout(createWorkoutReqDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {CreateWorkoutSpecificationReqDto} createWorkoutSpecificationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async createWorkoutSpecification(
      person: string,
      createWorkoutSpecificationReqDto: CreateWorkoutSpecificationReqDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkoutSpecificationDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.createWorkoutSpecification(
        person,
        createWorkoutSpecificationReqDto,
        options
      )
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} workout
     * @param {boolean} [animations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkout(
      workout: string,
      animations?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<WorkoutDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkout(workout, animations, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {string} person
     * @param {string} from
     * @param {string} to
     * @param {boolean} [animations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getWorkouts(
      person: string,
      from: string,
      to: string,
      animations?: boolean,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<WorkoutDto>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getWorkouts(person, from, to, animations, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
    /**
     *
     * @param {WorkoutResultDto} workoutResultDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async saveWorkout(
      workoutResultDto: WorkoutResultDto,
      options?: AxiosRequestConfig
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ActivitySessionDto>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.saveWorkout(workoutResultDto, options)
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)
    },
  }
}

/**
 * WorkoutApi - factory interface
 * @export
 */
export const WorkoutApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
  const localVarFp = WorkoutApiFp(configuration)
  return {
    /**
     *
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    animations(options?: any): AxiosPromise<Array<AnimationDto>> {
      return localVarFp.animations(options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {CreateWorkoutReqDto} createWorkoutReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWorkout(createWorkoutReqDto: CreateWorkoutReqDto, options?: any): AxiosPromise<WorkoutDto> {
      return localVarFp.createWorkout(createWorkoutReqDto, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {CreateWorkoutSpecificationReqDto} createWorkoutSpecificationReqDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    createWorkoutSpecification(
      person: string,
      createWorkoutSpecificationReqDto: CreateWorkoutSpecificationReqDto,
      options?: any
    ): AxiosPromise<WorkoutSpecificationDto> {
      return localVarFp
        .createWorkoutSpecification(person, createWorkoutSpecificationReqDto, options)
        .then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} workout
     * @param {boolean} [animations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkout(workout: string, animations?: boolean, options?: any): AxiosPromise<WorkoutDto> {
      return localVarFp.getWorkout(workout, animations, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {string} person
     * @param {string} from
     * @param {string} to
     * @param {boolean} [animations]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getWorkouts(
      person: string,
      from: string,
      to: string,
      animations?: boolean,
      options?: any
    ): AxiosPromise<Array<WorkoutDto>> {
      return localVarFp.getWorkouts(person, from, to, animations, options).then((request) => request(axios, basePath))
    },
    /**
     *
     * @param {WorkoutResultDto} workoutResultDto
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    saveWorkout(workoutResultDto: WorkoutResultDto, options?: any): AxiosPromise<ActivitySessionDto> {
      return localVarFp.saveWorkout(workoutResultDto, options).then((request) => request(axios, basePath))
    },
  }
}

/**
 * WorkoutApi - object-oriented interface
 * @export
 * @class WorkoutApi
 * @extends {BaseAPI}
 */
export class WorkoutApi extends BaseAPI {
  /**
   *
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutApi
   */
  public animations(options?: AxiosRequestConfig) {
    return WorkoutApiFp(this.configuration)
      .animations(options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {CreateWorkoutReqDto} createWorkoutReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutApi
   */
  public createWorkout(createWorkoutReqDto: CreateWorkoutReqDto, options?: AxiosRequestConfig) {
    return WorkoutApiFp(this.configuration)
      .createWorkout(createWorkoutReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {CreateWorkoutSpecificationReqDto} createWorkoutSpecificationReqDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutApi
   */
  public createWorkoutSpecification(
    person: string,
    createWorkoutSpecificationReqDto: CreateWorkoutSpecificationReqDto,
    options?: AxiosRequestConfig
  ) {
    return WorkoutApiFp(this.configuration)
      .createWorkoutSpecification(person, createWorkoutSpecificationReqDto, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} workout
   * @param {boolean} [animations]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutApi
   */
  public getWorkout(workout: string, animations?: boolean, options?: AxiosRequestConfig) {
    return WorkoutApiFp(this.configuration)
      .getWorkout(workout, animations, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {string} person
   * @param {string} from
   * @param {string} to
   * @param {boolean} [animations]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutApi
   */
  public getWorkouts(person: string, from: string, to: string, animations?: boolean, options?: AxiosRequestConfig) {
    return WorkoutApiFp(this.configuration)
      .getWorkouts(person, from, to, animations, options)
      .then((request) => request(this.axios, this.basePath))
  }

  /**
   *
   * @param {WorkoutResultDto} workoutResultDto
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof WorkoutApi
   */
  public saveWorkout(workoutResultDto: WorkoutResultDto, options?: AxiosRequestConfig) {
    return WorkoutApiFp(this.configuration)
      .saveWorkout(workoutResultDto, options)
      .then((request) => request(this.axios, this.basePath))
  }
}
