import { VmsCard } from "../components/VmsCard"
import { useAuthContext } from "../context/AuthContext"
import { useIndividualContext } from "../context/IndividualContext"
import { PersonWeekDto } from "../statera"
import Deck from "../components/Deck"
import { YearWeek } from "../utils/year-week"
import { useEffect, useState } from "react"
import { Link } from "react-router-dom"

export default function MyVimScore() {
  const { getPersonWeek, loading } = useIndividualContext()
  const { user } = useAuthContext()

  const [personData, setPersonData] = useState<PersonWeekDto>()
  const [currentWeek, _setWeek] = useState<YearWeek>(YearWeek.today())

  useEffect(() => {
    if (user?.person?.uuid)
      getPersonWeek(currentWeek, user.person.uuid).then((res: PersonWeekDto | undefined) => {
        setPersonData(res)
      })
  }, [user, currentWeek])

  const challenges = user?.challenges || []

  return (
    <div>
      <h2>{user?.person?.name && `Welcome ${user?.person?.name}`}</h2>
      <h3>My challenges</h3>
      {challenges.map((c, index) => (
        <VmsCard key={index} className="mb-4">
          <h2>{c.name}</h2>
          {c.name != "Vim & Art" ? <></> :
            <>
              <p>
                Kunstlotteri: Du bytter litt aktivitet mot lodd i lotteriet. For hver uke du gjennomfører 20 minutter
                med øvelser får du et lodd og er med i trekningen. Jo flere uker du klarer jo flere lodd blir dine.
              </p>
              <p>
                Bruk VimScore appen i ferien: VimScore gir deg balanserte treningsøkter tilpasset hvor mye tid du har
                nå, enten du er på treningssenteret, hjemme eller på ferie.I løpet av fellesferien sender vi deg forslag
                til øvelser spesielt tilpasset til de som er på ferietur.
              </p>
              <p>
                Trekning: Fredag 5. august kl 12 så trekker vi en vinner av kunstverket til Susann Brekke i kantinen på
                Technopolis.
              </p>
            </>
          }
          <Link to={"/challenges/" + c.uuid}>Oversikt</Link>
        </VmsCard>
      ))}
      {challenges.length > 0 ? <></> :
        <p>
          You are not in any challenges yet.
        </p>
      }

      {!window ? <Deck personWeek={personData} loading={loading} /> : <></>}

      <h3>What is a VimScore Challenge?</h3>
      <VmsCard>
        <p>
          We have two kinds of challenges, public challenges for everyone and custom challenges for your company.
        </p>
        <p>
          The public challenges are made to motivate everyone to become a little more active.
        </p>
        <p>
          The custom challenges are designed specifically for you and your company, where you make teams to help
          motivate each other. Send us an email at <a href="mailto:hello@vimscore.com">hello@vimscore.com</a> to get in
          touch.
        </p>
      </VmsCard>
    </div>
  )
}
