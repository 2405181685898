import { AxiosResponse } from "axios"

function delay(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms))
}

export async function runReport(res_: Promise<AxiosResponse>, setReportBusy: (_: boolean) => void) {
  setReportBusy(true)

  let res: AxiosResponse

  await delay(1000)

  try {
    res = await res_
  } finally {
    setReportBusy(false)
  }

  const contentDisposition = res.headers["content-disposition"]
  const contentType = res.headers["content-type"]

  // console.log("res", res)
  console.log("res.status", res.status)
  console.log("res.headers", res.headers)
  console.log("content-disposition", contentDisposition)
  console.log("content-type", contentType)

  const binaryData = [res.data]
  const filename = "wat.xlsx"

  const href = window.URL.createObjectURL(new Blob(binaryData))

  const a = document.createElement("a")
  a.href = href
  a.setAttribute("download", filename)
  document.body.appendChild(a)
  a.click()
  document.body.removeChild(a)
}
