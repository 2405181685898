import { ChallengeMemberWeekDto } from "../statera"
import { ChallengeWeek, ChallengeTeamData } from "../types"
import ErrorCard from "./ErrorCard"
import ProgressMatrix from "./ProgressMatrix"

type ChallengeViewProp = {
  challengeWeek?: ChallengeWeek
  teamData: ChallengeTeamData
}

export default function ChallengeView(props: ChallengeViewProp) {
  const { challengeWeek, teamData } = props

  const teamMembers = challengeWeek
      ?.members
      ?.filter((member: ChallengeMemberWeekDto) => teamData.teamMembers?.includes(member.person))
    || []

  return (
    <>
      {teamMembers.length > 0 && challengeWeek?.teams ? (
        <>
          {<ProgressMatrix members={teamMembers} title={teamData.teamName} />}
          {<ProgressMatrix members={challengeWeek.teams} title={challengeWeek.name} />}
        </>
      ) : (
        <div className="flex items-center justify-center">
          <ErrorCard
            title={"Ops !"}
            description={"Det finnes ikke noe data for denne challengen med den valgte uken"}
          />
        </div>
      )}
    </>
  )
}
