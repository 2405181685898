class RGBA {
  constructor(public readonly r: number,
              public readonly g: number,
              public readonly b: number,
              public readonly a?: number) {}

  static from256(r: number, g: number, b: number, a?: number): RGBA {
    return new RGBA(r / 256, g / 256, b / 256, a ? a / 256 : undefined)
  }

  toCss(): string {
    return `rgba(${Math.round(this.r * 100)}%, ${Math.round(this.g * 100)}%, ${Math.round(this.b * 100)}%, ${Math.round((this.a || 1) * 100)}%)`
  }

  withAlpha(a: number) {
    return new RGBA(this.r, this.g, this.b, a)
  }
}

export const mainRed = RGBA.from256(236, 101, 104) // "#EC6568"
export const darkRed = RGBA.from256(114, 9, 34) // "#720922"
export const mainBlue = RGBA.from256(0, 153, 204) // "#0099CC"
export const darkBlue = RGBA.from256(53, 58, 141) // "#353A8D"
export const mainGreen = RGBA.from256(111, 190, 149) // "#6FBE95"
export const darkGreen = RGBA.from256(2, 102, 102) // "#026666"
export const mainOrange = RGBA.from256(243, 150, 105) // "#F39669"
export const primaryColor = RGBA.from256(255, 66, 40) // "#FF42281A"
export const grey = RGBA.from256(255, 79, 79) // "#FF4F4F4F"
export const background = RGBA.from256(255, 243, 242) // "#FFF3F2F0"
export const white = RGBA.from256(255, 255, 255) // "#FFFFFFFF"
export const black = RGBA.from256(0, 0, 0) // "#FF000000"

export const strength = mainRed
export const endurance = mainBlue
export const balance = mainGreen
export const darkOrange = mainRed
export const flexibility = mainOrange
export const accentColor = mainGreen

type CapacityNames = "strength" | "endurance" | "flexibility" | "balance";

export const colorDictionary: { [K in CapacityNames]: { "main": RGBA, "dark": RGBA } } = {
  strength: {
    main: mainRed,
    dark: darkRed,
  },
  endurance: {
    main: mainBlue,
    dark: darkBlue,
  },
  flexibility: {
    main: mainOrange,
    dark: darkOrange,
  },
  balance: {
    main: mainGreen,
    dark: darkGreen,
  },
}
