import { createContext, useContext, useState } from "react"
import { PersonWeekDto } from "../statera"
import { activityControllerApi } from "../utils/api"
import { YearWeek } from "../utils/year-week"

interface IndividualContextInterface {
  loading: boolean
  getPersonWeek: (week: YearWeek, userId: string) => Promise<PersonWeekDto | undefined>
}

type PersonWeeks = {
  [key: string]: PersonWeekDto
}

const IndividualContext = createContext<IndividualContextInterface>({} as IndividualContextInterface)

// create context provider
function IndividualContextProvider({ children }: any) {
  const [personWeeks, setPersonWeeks] = useState<PersonWeeks>({} as PersonWeeks)
  const [loading, setLoading] = useState<boolean>(false)

  const getPersonWeek = async (week: YearWeek, userId: string) => {
    if (week.toString() in personWeeks) {
      return personWeeks[week.toString()]
    }

    setLoading(true)
    try {
      const { data } = await activityControllerApi.getPersonWeek(userId, week.toString())

      setPersonWeeks({ ...personWeeks, [week.toString()]: data })

      return data
    } catch (err) {
      console.log(err)
    } finally {
      setLoading(false)
    }
  }

  return (
    <IndividualContext.Provider value={{ loading, getPersonWeek }}>
      {children}
    </IndividualContext.Provider>
  )
}

const useIndividualContext = () => {
  // get the context
  const context = useContext(IndividualContext)

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useIndividualContext was used outside its Provider")
  }

  return context
}

export { useIndividualContext, IndividualContextProvider }
