import { useEffect, useState } from "react"
import { useAuthContext } from "../context/AuthContext"
import { useChallengeContext } from "../context/ChallengeContext"
import { useNavigate, useParams } from "react-router-dom"
import { ChallengeTeamData, ChallengeWeek, findMember } from "../types"
import IndividualChallenge from "../components/IndividualChallenge"
import TeamChallenge from "../components/TeamChallenge"
import WeekPicker from "../components/WeekPicker"
import { ChallengeDetailsDto, ChallengeReportReqDto } from "../statera"
import { challengeApi } from "../utils/api"
import { runReport } from "../utils/runReport"
import { YearWeek } from "../utils/year-week"

export default function ChallengeView() {
  const navigate = useNavigate()
  const { challengeUuid, week } = useParams()
  const [teamData, setTeamData] = useState<ChallengeTeamData>({} as ChallengeTeamData)
  const [challengeDetails, setChallengeDetails] = useState<ChallengeDetailsDto>()
  const [challengeWeek, setChallengeWeek] = useState<ChallengeWeek>()
  const [reportBusy, setReportBusy] = useState<boolean>(false)
  const { getChallengeDetails, getChallengeWeek, getTeamData, loading } = useChallengeContext()
  const { user } = useAuthContext()

  useEffect(() => {
    let currentWeek = YearWeek.parse(week || "")

    if (currentWeek == undefined) {
      navigate(`/challenges/${challengeUuid}/${YearWeek.today()}`, {replace: true})
      return
    }

    if (challengeUuid) {
      Promise.all([
        getChallengeWeek(challengeUuid, currentWeek),
        getChallengeDetails(challengeUuid),
        user?.person?.uuid ? getTeamData(challengeUuid, user.person.uuid) : Promise.resolve(null),
      ]).then((values) => {
        const [challengeWeek, challengeDetails, teamData] = values
        setChallengeWeek(challengeWeek)
        setChallengeDetails(challengeDetails)
        if (teamData) {
          setTeamData(teamData)
        }
      })
    }
  }, [challengeUuid, week, user])

  const challenge = (user?.challenges || []).find(c => c.uuid == challengeUuid)
  const teamChallenge = !!(challenge && challenge.teamChallenge)

  const myMembership = findMember(challengeDetails, user?.person?.uuid)
  const adminFlag = myMembership?.adminFlag

  async function downloadReport() {
    if (!challengeUuid) {
      return
    }
    console.log("download report")
    const req: ChallengeReportReqDto = {
      humanMode: true,
    }
    const res = challengeApi.getChallengeReport(challengeUuid, req, {
      responseType: "blob",
      onDownloadProgress: (event) => {
        console.log("download event", event)
      },
    })

    await runReport(res, setReportBusy)

    return undefined
  }

  let currentWeek: YearWeek = YearWeek.parse(week || "") || YearWeek.today()

  const setWeek = (week: YearWeek) => {
    navigate(`/challenges/${challengeUuid}/${week}`)
  }

  let report = <></>

  if (adminFlag) {
    /*
    if (reportBusy) {
      report = <span>BUSY</span>
    } else {
      report = <button disabled={reportBusy} className="ml-4" onClick={downloadReport}>Download report</button>
    }
    */

    report = <button disabled={reportBusy} className="ml-4" onClick={downloadReport}>Download report</button>
  }

  let startWeek = challengeDetails?.startWeek
  let firstWeek = startWeek == null ? undefined : YearWeek.parse(startWeek)

  return (
    <div>
      <div className="flex flex-col space-y-8">
        <h2>
          {challengeWeek?.name}
          {report}
        </h2>
        <WeekPicker currentWeek={currentWeek} setWeek={setWeek} firstWeek={firstWeek} />
      </div>
      <div className={`${loading && "blur-lg"} space-y-8`}>
        {teamChallenge ? (
          <TeamChallenge challengeWeek={challengeWeek} teamData={teamData} />
        ) : (
          <IndividualChallenge challengeWeek={challengeWeek} />
        )}
      </div>
    </div>
  )
}
