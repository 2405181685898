import { Capacity } from "../types"
import { colorDictionary } from "../assets/constants"
import Separator from "./Separator"

type ProgressBarProps = {
  progress: number
  capacity: Capacity
}

/*
This component consists of three parts
1. a background poiechart that has 30% opacity
2. a piechart representing the value passed in as props3.
and the white lines setperating each pie
*/
export function CircularProgressBar(props: ProgressBarProps) {
  const { progress, capacity } = props
  const percentage = progress > 1 ? 1 : progress

  // const fullCircleString = `${colorDictionary[capacity].main} 0deg 45deg, ${colorDictionary[capacity].dark} 45deg 90deg`

  const NUMBER_OF_PIES = 8

  const calculateNumberOfPies = (value: number) => {
    if (value <= 0) {
      return 0
    }

    if (value >= 1) {
      return NUMBER_OF_PIES
    }

    return Math.ceil(value * NUMBER_OF_PIES)
  }

  const createPieChart = () => {
    const fullColor = calculateNumberOfPies(percentage)
    let capacityColor = colorDictionary[capacity]
    let text = ""
    for (let i = 0; i < NUMBER_OF_PIES; i++) {
      const pieColor = i % 2 == 0 ? capacityColor.main : capacityColor.dark
      const color = i < fullColor ? pieColor : pieColor.withAlpha(0.15)
      if (text.length != 0) {
        text += ", "
      }
      text += `${color.toCss()} ${i * 45}deg ${(i + 1) * 45}deg`
    }

    return text
  }

  return (
    <div
      className="w-12 h-12 md:w-16 md:h-16 lg:w-24 lg:h-24"
      style={{
        borderRadius: "50%",
        position: "relative",
      }}
    >
      <div
        style={{
          position: "absolute",
          height: "100%",
          width: "100%",
          borderRadius: "50%",
          backgroundImage: `conic-gradient(${createPieChart()})`,
        }}
      />
      {/*
      <div
        style={{
          position: "absolute",
          opacity: 0.3,
          height: "100%",
          width: "100%",
          borderRadius: "50%",
          backgroundImage: `repeating-conic-gradient(${fullCircleString})`,
        }}
      />
      */}
      <div className="flex items-center justify-center w-12 h-12 md:w-16 md:h-16 lg:w-24 lg:h-24">
        {[45, 90, 135, 180].map((angle: number, key: number) => (
          <Separator angle={angle} key={key} />
        ))}
      </div>
    </div>
  )
}
