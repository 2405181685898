import { ArrowLeftIcon, ArrowRightIcon } from "@heroicons/react/solid"
import { YearWeek } from "../utils/year-week"

type WeekPickerProps = {
  currentWeek: YearWeek
  setWeek: (week: YearWeek) => void
  firstWeek?: YearWeek
}

export default function WeekPicker(props: WeekPickerProps) {
  const { currentWeek, setWeek, firstWeek } = props

  // console.log("current", currentWeek.toString(), "firstWeek", firstWeek);

  const incrementDate = () => {
    setWeek(currentWeek.next())
  }
  const decrementDate = () => {
    setWeek(currentWeek.previous())
  }

  const hideLeftButton = () => {
    return firstWeek != null && firstWeek.compareTo(currentWeek) >= 0
  }

  const hideRightButton = () => {
    return YearWeek.today().compareTo(currentWeek) <= 0
  }

  // console.log("hide", "left", hideLeftButton(), "right", hideRightButton());

  return (
    <div className="flex flex-row items-center justify-center pb-4 space-x-6">
      <button onClick={decrementDate} className={hideLeftButton() ? "invisible" : ""}>
        <ArrowLeftIcon className="w-7 h-7" />
      </button>
      <p className="text-lg">Week {currentWeek.week}</p>
      <button onClick={incrementDate} className={hideRightButton() ? "invisible" : ""}>
        <ArrowRightIcon className="w-7 h-7" />
      </button>
    </div>
  )
}
