import { useState } from "react"
import { useNavigate } from "react-router-dom"
import LoginButton from "../components/Buttons/Login"
import { useAuthContext } from "../context/AuthContext"
import { continueAuth } from "../utils/auth"

const EnterEmailToken = () => {
  const { setAccessToken, deviceToken } = useAuthContext()
  const [code, setCode] = useState("")
  const [inputError, setInputError] = useState<string | undefined>()

  const navigate = useNavigate()

  async function handleLogInClick() {
    if (!deviceToken) {
      alert("Error, please refresh page")
      return
    }
    const accessToken = await continueAuth(code, deviceToken)
    if (accessToken) {
      setAccessToken(accessToken)
      navigate("/me")
    } else {
      setInputError("Wrong token!")
    }
  }

  return (
    <div className="flex items-center justify-center h-full min-h-screen py-2 bg-whitesmoke ">
      <div className="flex items-center justify-center min-h-full px-4 py-12 bg-white shadow-xl rounded-xl sm:px-6 lg:px-8">
        <div className="w-full space-y-24 max-w-7xl">
          <div>
            <h2 className="mt-6 text-4xl font-extrabold text-left text-gray-900">Log in</h2>
            <p className="mt-4 text-left text-gray-600 text-m">Token</p>
            <input
              onChange={(e) => {
                setCode(e.target.value)
              }}
              value={code}
              className="w-full mt-2 border-b-2 border-black border-solid focus:border-black"
              id="code"
              type="text"
              placeholder="******"
              required
            />
            <p className="font-medium text-red-600">{inputError}</p>
            <div className="flex justify-center">
              <LoginButton onClick={handleLogInClick} />
            </div>
            {/*
                        <div className="max-w-sm">
                            <p className="mt-2 text-left text-gray-600 text-m">
                                When you get the email form us, enter the token here
                            </p>
                        </div>
                        */}
          </div>
        </div>
      </div>
    </div>
  )
}

export default EnterEmailToken
