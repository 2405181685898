import { CircularProgressBar } from "./CircularProgressBar"
import { XIcon, CheckIcon } from "@heroicons/react/solid"
import { HasProgress } from "../types"
import React from "react"

type ProgressMatrixProps = {
  members: HasProgress[]
  title: string | undefined
}

export default function ProgressMatrix(props: ProgressMatrixProps) {
  const { members, title } = props
  // should take in array of users

  members.sort((a, b) => a.name.localeCompare(b.name))

  return (
    <div className="flex flex-col overflow-x-auto card">
      <h3>{title || "progress"}</h3>
      <table className="table-fixed border-collapse [border-spacing:2rem] w-full ">
        <thead>
        <tr className="hidden sm:table-row">
          <th className="sm:w-auto" />
          <th className="sm:w-auto">Strength</th>
          <th className="sm:w-auto">Endurance</th>
          <th className="sm:w-auto">Balance</th>
          <th className="sm:w-auto">Flexibility</th>
          <th className="w-[50px]">Done</th>
        </tr>
        </thead>
        <tbody>
        {members.map((member, index: number) => {
          const progress = member.progress

          if (!progress) {
            return <></>
          }

          return (
            <React.Fragment key={index}>
              <tr>
                <td className="pt-4 sm:td md:hidden" colSpan={5}>{member.name}</td>
              </tr>
              <tr>
                <td className="w-full hidden md:table-cell">
                  {/* <div className="flex flex-col items-center justify-center w-12 h-12 m-0 text-xs break-all md:-m-4 xl:m-0 md:w-24 md:h-24 lg:w-32 lg:h-32 md:text-base lg:text-lg"> */}
                  <div className="text-xs break-words md:text-base lg:text-lg">{member.name}</div>
                </td>
                <td className="pt-4">
                  <div className="flex justify-center lg:m-0">
                    <CircularProgressBar progress={progress.strength} capacity={"strength"} />
                  </div>
                </td>
                <td className="pt-4">
                  <div className="flex justify-center lg:m-0">
                    <CircularProgressBar progress={progress.endurance} capacity={"endurance"} />
                  </div>
                </td>
                <td className="pt-4">
                  <div className="flex justify-center lg:m-0">
                    <CircularProgressBar progress={progress.balance} capacity={"balance"} />
                  </div>
                </td>
                <td className="pt-4">
                  <div className="flex justify-center lg:m-0">
                    <CircularProgressBar progress={progress.flexibility} capacity={"flexibility"} />
                  </div>
                </td>
                <td>
                  <div className="flex flex-col items-center justify-center whitespace-nowrap">
                    {member.completed ? (
                      <CheckIcon className="w-8 h-8 text-green-600 xs:scale-75 md:scale-100 lg:scale-125 xl:scale-150" />
                    ) : (
                      <XIcon className="w-8 h-8 text-red-600 xs:scale-75 md:scale-100 lg:scale-125 xl:scale-150" />
                    )}
                  </div>
                </td>
              </tr>
            </React.Fragment>)
        })}
        </tbody>
      </table>
    </div>
  )
}
