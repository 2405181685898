import { Configuration, ActivityControllerApi, UserApi, ChallengeApi, PersonApi } from "../statera"

import axios from "axios"

let basePath = process.env.REACT_APP_STATERA_URL || "https://statera.ci.vimscore.com"

if (window && window.location && window.location.protocol == "https:") {
  const hostname = window.location.hostname

  if (hostname == "my.vimscore.com") {
    basePath = "https://statera.prod.vimscore.com"
  } else {
    const match = /my\.(.*)\.vimscore.com/.exec(hostname)
    if (match && match.length == 2) {
      basePath = `https://statera.${match[1]}.vimscore.com`
    }
  }
}

const config = new Configuration({
  basePath: basePath,
})

const axiosInstance = axios.create()

const activityControllerApi = new ActivityControllerApi(config, config.basePath, axiosInstance)
const userApi = new UserApi(config, config.basePath, axiosInstance)
const challengeApi = new ChallengeApi(config, config.basePath, axiosInstance)
const personApi = new PersonApi(config, config.basePath, axiosInstance)

export { activityControllerApi, userApi, challengeApi, personApi, axiosInstance }
