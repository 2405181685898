import { convertDurationDtoToNumberOfSek } from "../helpers/convertDurationDtoToNumberOfSek"
import { PersonWeekDto } from "../statera"
import { Card } from "./Card"

type DeckProp = {
  personWeek?: PersonWeekDto
  loading: boolean
}

export default function Deck(props: DeckProp) {
  const { personWeek, loading } = props

  return (
    <div className={`grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-4 ${loading && "blur-lg"}`}>
      <Card
        total={convertDurationDtoToNumberOfSek(personWeek?.given?.strength)}
        progress={convertDurationDtoToNumberOfSek(personWeek?.done?.strength)}
        color={"#Ec6568"}
        capacity={"strength"}
        completed={personWeek?.completed}
      />
      <Card
        total={convertDurationDtoToNumberOfSek(personWeek?.given?.endurance)}
        progress={convertDurationDtoToNumberOfSek(personWeek?.done?.endurance)}
        color={"#0099CD"}
        capacity={"endurance"}
        completed={personWeek?.completed}
      />
      <Card
        total={convertDurationDtoToNumberOfSek(personWeek?.given?.balance)}
        progress={convertDurationDtoToNumberOfSek(personWeek?.done?.balance)}
        color={"#6FBE95"}
        capacity={"balance"}
        completed={personWeek?.completed}
      />
      <Card
        total={convertDurationDtoToNumberOfSek(personWeek?.given?.flexibility)}
        progress={convertDurationDtoToNumberOfSek(personWeek?.done?.flexibility)}
        color={"#F39669"}
        capacity={"flexibility"}
        completed={personWeek?.completed}
      />
    </div>
  )
}
