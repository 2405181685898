import { AxiosResponse } from "axios"
import {
  ContinueEmailAuthenticationReqDto,
  ContinueEmailAuthenticationResDto,
  StartEmailAuthenticationReqDto,
  StartEmailAuthenticationResDto,
} from "../statera"
import { personApi } from "./api"

export async function startAuth(email: string, deviceToken: string) {
  const credentials: StartEmailAuthenticationReqDto = {
    email,
    deviceToken,
  }
  try {
    return await personApi
      .startEmailAuthentication(credentials)
      .then(function({ data }: AxiosResponse<StartEmailAuthenticationResDto, any>) {
        if (data.code === "OK") {
          return true
        }
      })
      .catch(function(error: Error) {
        console.log(error)
        return false
      })
  } catch (error: any) {
    console.log(error)
    return false
  }
}

export async function continueAuth(emailToken: string, deviceToken: string) {
  const credentials: ContinueEmailAuthenticationReqDto = {
    emailToken,
    deviceToken,
  }
  try {
    return await personApi
      .continueEmailAuthentication(credentials)
      .then(({ data }: AxiosResponse<ContinueEmailAuthenticationResDto, any>) => {
        return data.authenticationToken
      })
      .catch(function(error: Error) {
        console.log(error)
        return undefined
      })
  } catch (error: any) {
    console.log(error)
    return undefined
  }
}
