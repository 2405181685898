import { createContext, useContext, useState } from "react"
import { ChallengeDetailsDto, ChallengeMemberDto, ChallengeTeamDto, ChallengeWeekDto } from "../statera"
import { ChallengeTeamData } from "../types"
import { challengeApi } from "../utils/api"
import { YearWeek } from "../utils/year-week"

interface ChallengeContextInterface {
  loading: boolean
  getChallengeDetails: (challengeUuid: string) => Promise<ChallengeDetailsDto | undefined>
  getChallengeWeek: (challengeUuid: string, week: YearWeek) => Promise<ChallengeWeekDto | undefined>
  getTeamData: (challengeUuid: string, personUuid: string) => Promise<ChallengeTeamData | undefined>
}

const ChallengeContext = createContext<ChallengeContextInterface>({} as ChallengeContextInterface)

// create context provider
function ChallengeContextProvider({ children }: any) {
  const [loading, setLoading] = useState<boolean>(false)
  const [challengeDetails, setChallengeDetails] = useState<{ [key: string]: ChallengeDetailsDto }>({})
  const [challengeWeeks, setChallengeWeeks] = useState<{ [key: string]: ChallengeWeekDto }>({})
  const [yourTeams, setYourTeams] = useState<{ [key: string]: ChallengeTeamData }>({})

  async function getChallengeDetails(challengeUuid: string) {
    let value = challengeDetails[challengeUuid]
    if (value) {
      return value
    }

    const { data } = await challengeApi.getChallengeDetails(challengeUuid)

    setChallengeDetails({ ...challengeDetails, [challengeUuid]: data })

    return data
  }

  const getTeamData = async (challengeUuid: string, personUuid: string) => {
    let value = yourTeams[challengeUuid]
    if (value) {
      return value
    }

    try {
      // const { data } = await challengeApi.getChallengeDetails(challengeUuid)
      const data = await getChallengeDetails(challengeUuid)

      const { members, teams } = data

      if (!members) return undefined
      if (!teams) return undefined

      const findMyself = members.find((member: ChallengeMemberDto) => member.person === personUuid)

      const teamUuid = findMyself?.team

      const teamMembers = members
        .filter((member: ChallengeMemberDto) => member.team === teamUuid)
        .map((member: ChallengeMemberDto) => member.person)

      const team = teams.find((team: ChallengeTeamDto) => team.uuid === teamUuid)
      const teamData: ChallengeTeamData = {
        teamMembers: teamMembers,
        teamName: team?.name,
      }
      setYourTeams({ ...yourTeams, [challengeUuid]: teamData })
      return teamData
    } catch (err) {
      console.log(err)
    }
  }

  const getChallengeWeek = async (challengeUuid: string, week: YearWeek) => {
    const key = challengeUuid + "/" + week.toString()
    let value = challengeWeeks[key]

    if (value) {
      return value
    }

    setLoading(true)

    try {
      const { data } = await challengeApi.getChallengeWeek(challengeUuid, week.toString())

      setChallengeWeeks({ ...challengeWeeks, [key]: data })
      return data
    } catch (err) {
      console.log(err)
      throw err
    } finally {
      setLoading(false)
    }
  }

  return (
    <ChallengeContext.Provider value={{ getChallengeDetails, getChallengeWeek, getTeamData, loading }}>
      {children}
    </ChallengeContext.Provider>
  )
}

const useChallengeContext = () => {
  // get the context
  const context = useContext(ChallengeContext)

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useChallengeContext was used outside its Provider")
  }

  return context
}

export { useChallengeContext, ChallengeContextProvider }
