import React from "react"

type VmsCardProps = {
  className?: string | undefined,
  children: React.ReactNode,
}

export function VmsCard(props: VmsCardProps) {
  return (
    <div className={"card " + (props.className || "")}>
      {props.children}
    </div>
  )
}
