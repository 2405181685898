type SeparatorProps = {
  angle: number
}

export default function Separator({ angle }: SeparatorProps) {
  return (
    <div
      style={{
        borderColor: "white",
        borderTopWidth: "3px",
        position: "absolute",
        width: "100%",
        transform: `rotate(${angle}deg)`,
      }}
    />
  )
}
