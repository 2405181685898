import {
  CapacityMinutesDto,
  CapacityProgressDto,
  ChallengeDetailsDto,
  ChallengeMemberDto,
  ChallengeWeekDto,
} from "../statera"

export type Capacity = "strength" | "endurance" | "flexibility" | "balance"

export type ProtectedRouteProps = {
  isAuthenticated: boolean
  outlet: JSX.Element
  redirectTo: string
}

export type ChallengeWeek = ChallengeWeekDto & {
  teamMembers?: string[]
}

export type ChallengeTeamData = {
  teamMembers: string[]
  teamName?: string
}

export interface HasProgress {
  name: string
  given: CapacityMinutesDto
  done: CapacityMinutesDto
  progress: CapacityProgressDto
  completed?: boolean
}

export function findMember(challenge?: ChallengeDetailsDto, personUuid?: string): ChallengeMemberDto | undefined {
  if (!challenge || !personUuid) {
    return
  }

  return challenge.members?.find(m => m.person == personUuid)
}
