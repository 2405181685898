import { ChallengeWeekDto } from "../statera"
import ErrorCard from "./ErrorCard"
import ProgressMatrix from "./ProgressMatrix"

type ChallengeViewProp = {
  challengeWeek?: ChallengeWeekDto
}

export default function ChallengeView(props: ChallengeViewProp) {
  const { challengeWeek } = props

  const members = challengeWeek?.members || []

  return (
    <>
      {challengeWeek?.name && members.length > 0 ? (
        <ProgressMatrix members={members} title={challengeWeek.name} />
      ) : (
        <div className="flex items-center justify-center">
          <ErrorCard
            title={"Ops !"}
            description={"Det finnes ikke noe data for denne challengen med den valgte uken"}
          />
        </div>
      )}
    </>
  )
}
