import { useState } from "react"
import { useNavigate } from "react-router-dom"
import EpostButton from "../components/Buttons/Epost"
import { stringIsMailAdress } from "../helpers/stringIsMailAdress"
import { startAuth } from "../utils/auth"

import { useAuthContext } from "../context/AuthContext"
import { useEffect } from "react"

export default function LoginView() {
  const { accessToken, deviceToken } = useAuthContext()
  const [email, setEmail] = useState("")
  const [inputError, setInputError] = useState<string | undefined>()
  const navigate = useNavigate()
  useEffect(() => {
    if (accessToken) {
      navigate("/me")
    }
  }, [])

  function handleEpostClick() {
    if (!deviceToken) {
      alert("Error, please refresh page")
      return
    }
    if (stringIsMailAdress(email)) {
      startAuth(email, deviceToken)
      navigate("/auth")
    } else {
      setInputError("Please type a valid email")
    }
  }

  return (
    <div className="flex items-center justify-center h-full min-h-screen py-2 bg-whitesmoke ">
      <div className="flex items-center justify-center min-h-full px-4 py-12 bg-white shadow-xl rounded-xl sm:px-6 lg:px-8">
        <div className="w-full space-y-24 max-w-7xl">
          <div>
            <h2 className="mt-6 text-4xl font-extrabold text-left text-gray-900">Log in</h2>
            <p className="mt-4 text-left text-gray-600 text-m">Email</p>
            <input
              onChange={(e) => {
                setEmail(e.target.value)
              }}
              value={email}
              className="w-full mt-2 border-b-2 border-black border-solid focus:border-black"
              id="email"
              type="text"
              required
            />
            <p className="font-medium text-red-600">{inputError}</p>
            <div className="flex justify-center">
              <EpostButton onClick={handleEpostClick} />
            </div>
            <div className="max-w-sm">
              <p className="mt-2 text-left text-gray-600 text-m">When you click submit we will send you a token.</p>
              <p className="mt-2 text-left text-gray-600 text-m">
                If you don&apos;t have a VimScore account yet, you have to sign up through the app first.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
