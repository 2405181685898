import { AxiosResponse } from "axios"
import { createContext, useContext, useEffect, useState } from "react"
import { v4 as uuidv4 } from "uuid"
import { WhoAmIResponseDto } from "../statera"
import { userApi } from "../utils/api"

interface AuthContextInterface {
  accessToken: string | undefined
  setAccessToken: (accessToken: string) => void
  deviceToken: string | undefined
  user: WhoAmIResponseDto | undefined
  isAuthenticated: () => boolean
}

export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface)

// create context provider
function AuthContextProvider({ children }: any) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [deviceToken] = useState<string>(uuidv4())
  const [user, setUser] = useState<WhoAmIResponseDto | undefined>()
  const [accessToken, setStoredValue] = useState<string>(() => {
    try {
      // Get from local storage by key
      const item = localStorage.getItem("accessToken")
      // Parse stored json or if none return initialValue
      return item ? JSON.parse(item) : ""
    } catch (error) {
      // If error also return initialValue
      console.log(error)
      return ""
    }
  })

  useEffect(() => {
    if (accessToken && !user) {
      userApi.whoAmI().then((res: AxiosResponse<WhoAmIResponseDto, any>) => {
        if (res?.data) {
          setUser(res.data)
        }
      })
    }
  }, [])

  const isAuthenticated = () => {
    return !!accessToken
  }

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setAccessToken = (value: string | ((val: string) => string)) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(accessToken) : value
      // Save state
      setStoredValue(valueToStore)
      // Save to local storage
      localStorage.setItem("accessToken", JSON.stringify(valueToStore))
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error)
    }
  }
  return (
    <AuthContext.Provider value={{ accessToken, setAccessToken, deviceToken, user, isAuthenticated }}>
      {children}
    </AuthContext.Provider>
  )
}

const useAuthContext = (): AuthContextInterface => {
  // get the context
  const context = useContext(AuthContext)

  // if `undefined`, throw an error
  if (context === undefined) {
    throw new Error("useUserContext was used outside of its Provider")
  }

  return context
}

export { useAuthContext, AuthContextProvider }
