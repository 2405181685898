import VimscoreLogo from "../assets/images/vimscore-logo-colorful.png"

type ErrorCardProps = {
  title: string
  description: string
}

export default function ErrorCard({ title, description }: ErrorCardProps) {
  return (
    <div className="flex flex-col items-center justify-center space-y-12 card w-fit">
      <h2>{title}</h2>

      <img src={VimscoreLogo} className="h-10 md:h-24" />

      <p className="text-xl">{description}</p>
    </div>
  )
}
