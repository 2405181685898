import { capitalizeFirstLetter } from "../helpers/capitalizeFirstLetter"
import { Capacity } from "../types"
import { CircularProgressBar } from "./CircularProgressBar"
import { VmsCard } from "./VmsCard"

type CardProps = {
  total: number
  progress: number
  color: string
  capacity: Capacity
  completed?: boolean
}

export function Card(props: CardProps) {
  const { total, progress, color, capacity, completed } = props

  return (
    <VmsCard>
      <div>
        <span className="text-lg font-lg ">{capitalizeFirstLetter(capacity)}</span>
        {completed ? (
          <span className="inline-flex items-center justify-center px-1 py-1 mr-2 text-xs leading-none text-green-100 bg-green-500 rounded-full ">
            Completed
          </span>
        ) : (
          <div className="inline-flex items-center justify-center py-1 mr-2 leading-none bg-red-500 rounded-full min-w-max">
            <p className="inline-flex justify-center m-2 text-xs leading-none text-red-100 ">Not completed</p>
          </div>
        )}
      </div>
      <p className="flex justify-center text-base font-medium">Total: {total}</p>
      <p className="flex justify-center mb-4 text-base font-medium">Done: {progress}</p>
      <p className={`flex justify-center text-xm text-[${color}]`}>{total - progress} minutter igjen</p>
      <p className="flex justify-center text-xs"> å vimme denne uken </p>
      <div className="flex justify-center">
        <CircularProgressBar progress={progress} capacity={capacity} />
      </div>
    </VmsCard>
  )
}
